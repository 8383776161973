import dashboardPlaceholder from './../../../../assets/images/solutions/dashboard-placeholder.webp';

import {
  StyledContainer,
  StyledAdvantagesContainer,
  StyledImageContainer
} from './statisticsAndReports.style';
import features from './featuresList';

import BulletList from '../../../../components/bulletList/bulletList.component';
import AnimatedHeading from '../../../../utils/animatedHeading/animatedHeading';


const StatisticsAndReports = () => (
  <>
    <StyledContainer>
      <AnimatedHeading>Statistics<br /><span>and raports</span></AnimatedHeading>
      <StyledAdvantagesContainer>
        <BulletList items={features} />
      </StyledAdvantagesContainer>
      <StyledImageContainer>
        <img src={dashboardPlaceholder} alt='' />
        <div>
          <img src={dashboardPlaceholder} alt='' />
        </div>
        <img src={dashboardPlaceholder} alt='' />
      </StyledImageContainer>
    </StyledContainer>
  </>
);

export default StatisticsAndReports;