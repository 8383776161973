export const menuItems = [
  {
    title: 'Home',
    href: '/'
  },
  {
    title: 'About',
    href: '/#about'
  },
  {
    title: 'Case Studies',
    href: '/#case-study'
  },
  {
    title: 'Solutions',
    href: '/',
    submenu: [
      {
        title: 'Virtual Reality',
        href: '/solutions/vr'
      },
      {
        title: 'Augmented Reality',
        href: '/solutions/ar'
      },
      {
        title: 'Mixed Reality (TBA)',
        href: '/solutions/mr'
      },
      {
        title: 'Platform',
        href: '/solutions/pa'
      }
    ]
  },
  {
    title: 'Industries',
    href: '/#industries'
  },
  {
    title: 'Blog',
    href: '/#blog'
  },
  {
    title: 'Testimonials',
    href: '/#testimonials'
  },
  {
    title: 'Contact',
    href: '/#contact-us'
  },
];