import dashboardPlaceholder from './../../../../assets/images/solutions/dashboard-placeholder.webp';

import { StyledContainer, StyledImageContainer, StyledContentContainer } from './internalPlatform.style';

import useMedia from '../../../../hooks/useMedia';
import Donuts from '../../../../utils/donuts/donuts';
import P from '../../../../utils/paragraph/paragraph.style';
import AnimatedHeading from '../../../../utils/animatedHeading/animatedHeading';

const InternalPlatform = () => {
  const { isMobile } = useMedia();

  return (
    <>
      {!isMobile && <Donuts style={{ zIndex: '0' }} />}
      <StyledContainer>
        <StyledImageContainer>
          <img src={dashboardPlaceholder} alt='' />
        </StyledImageContainer>
        <StyledContentContainer>
          <div>
            <AnimatedHeading side='right'><span>Internal</span><br /> platform</AnimatedHeading>
            <P>
              Internal platform to increase sales efficiency of detailing process based on created VR and AR application. Containing geolocation and collecting data of durance, reception and quality of detailings.
            </P>
          </div>
        </StyledContentContainer>
      </StyledContainer>
    </>
  )
}

export default InternalPlatform;