import { useState } from 'react';
import { Transition, SwitchTransition } from 'react-transition-group';

import { ReactComponent as Background } from './../../../assets/images/testimonials/background.svg';
import { ReactComponent as QuoteIcon } from './../../../assets/images/testimonials/quote-icon.svg';
import { ReactComponent as Ellipse1 } from './../../../assets/images/testimonials/ellipse-1.svg';
import { ReactComponent as Ellipse2 } from './../../../assets/images/testimonials/ellipse-2.svg';
import { ReactComponent as Ellipse3 } from './../../../assets/images/testimonials/ellipse-3.svg';
import { ReactComponent as Ellipse4 } from './../../../assets/images/testimonials/ellipse-4.svg';
import { ReactComponent as Ellipse5 } from './../../../assets/images/testimonials/ellipse-5.svg';
import { ReactComponent as Ellipse6 } from './../../../assets/images/testimonials/ellipse-6.svg';
import { ReactComponent as Ellipse7 } from './../../../assets/images/testimonials/ellipse-7.svg';

import {
  StyledTestimonialContainer,
  StyledCloudContainer,
  StyledAvatar,
  P
} from './testimonials.style';
import { testimonialItems } from './testimonialsData';

import useMedia from '../../../hooks/useMedia';
import AnimatedHeading from '../../../utils/animatedHeading/animatedHeading';
import FloatingComponent from '../../../utils/floatingComponent/floatingComponent';


const Testimonials = () => {
  const [activeTestimonialIndex, setActiveTestimonialIndex] = useState(parseInt(Math.random() * 1000) % 6);
  const { isMobile } = useMedia();

  const transitionStyles = {
    entering: { opacity: 0.5 },
    entered: { opacity: 1 },
    exiting: { opacity: 0.5 },
    exited: { opacity: 0 }
  }

  const handleTestimonialChange = index => setActiveTestimonialIndex(index);
  const isTestimonialActive = index => index === activeTestimonialIndex ? 1 : 0;
  const getAvatar = index => testimonialItems[index].avatar;

  return (
    <>
      <StyledTestimonialContainer id='testimonials'>
        <Background />
        <AnimatedHeading>
          <span>Our customers</span><br />testimonials
        </AnimatedHeading>
        <StyledCloudContainer>
          <div className='column'>
            <div>
              {!isMobile &&
                <FloatingComponent additionalStyles={{ bottom: '20%', left: '0' }}>
                  <StyledAvatar active={isTestimonialActive(0)} src={getAvatar(0)} alt='' onClick={() => handleTestimonialChange(0)} width='90px' height='90px' />
                </FloatingComponent>
              }
              <FloatingComponent additionalStyles={{ top: '0', right: '0' }}>
                <Ellipse4 />
              </FloatingComponent>
            </div>
            {!isMobile &&
              <div>
                <FloatingComponent additionalStyles={{ top: '0%', left: '35%' }}>
                  <Ellipse6 />
                </FloatingComponent>
                <FloatingComponent additionalStyles={{ right: '15%', top: '-5%' }}>
                  <StyledAvatar active={isTestimonialActive(2)} src={getAvatar(2)} alt='' onClick={() => handleTestimonialChange(2)} width='140px' height='140px' />
                </FloatingComponent>
              </div>
            }
            <div>
              {!isMobile &&
                <FloatingComponent additionalStyles={{ top: '0%', left: '10%' }}>
                  <StyledAvatar active={isTestimonialActive(1)} src={getAvatar(1)} alt='' onClick={() => handleTestimonialChange(1)} width='61px' height='61px' />
                </FloatingComponent>
              }
              <FloatingComponent additionalStyles={{ top: '0%', right: '40%' }}>
                <Ellipse2 />
              </FloatingComponent>
            </div>
          </div>
          <SwitchTransition mode="out-in">
            <Transition key={activeTestimonialIndex} timeout={50} >
              {state => <div className='column middle' style={{ ...transitionStyles[state] }}>
                <img src={testimonialItems[activeTestimonialIndex].avatar} style={{}} alt='' width='150px' height='150px' />
                <div style={{ width: '46px', height: '46px', marginTop: '-25px' }}>
                  <Ellipse1 />
                  <QuoteIcon style={{ top: 'calc(50% - (29px / 2))', left: 'calc(50% - (23px / 2))' }} />
                </div>
                <h3>{testimonialItems[activeTestimonialIndex].name}</h3>
                <h4>{testimonialItems[activeTestimonialIndex].company}</h4>
                {!isMobile &&
                  <P>{testimonialItems[activeTestimonialIndex].testimonial}</P>
                }
              </div>}
            </Transition>
          </SwitchTransition>
          <div className='column'>
            <div>
              <FloatingComponent additionalStyles={{ left: '30%', bottom: '50%' }}>
                <Ellipse3 />
              </FloatingComponent>
              {!isMobile &&
                <FloatingComponent additionalStyles={{ top: '10%', right: '35%' }}>
                  <StyledAvatar active={isTestimonialActive(3)} src={getAvatar(3)} alt='' onClick={() => handleTestimonialChange(3)} width='77px' height='77px' />
                </FloatingComponent>
              }
            </div>
            {!isMobile &&
              <div>
                <FloatingComponent additionalStyles={{ bottom: '20%', left: '0%' }}>
                  <Ellipse5 />
                </FloatingComponent>
                <FloatingComponent additionalStyles={{ bottom: '15%', right: '5%' }}>
                  <StyledAvatar active={isTestimonialActive(5)} src={getAvatar(5)} alt='' onClick={() => handleTestimonialChange(5)} width='122px' height='122px' />
                </FloatingComponent>
              </div>
            }
            <div>
              {!isMobile &&
                <FloatingComponent additionalStyles={{ left: '25%', bottom: '10%' }}>
                  <StyledAvatar active={isTestimonialActive(4)} src={getAvatar(4)} alt='' onClick={() => handleTestimonialChange(4)} width='91px' height='91px' />
                </FloatingComponent>
              }
              <FloatingComponent additionalStyles={{ top: '20%', right: '15%' }}>
                <Ellipse7 />
              </FloatingComponent>
            </div>
          </div>
        </StyledCloudContainer>
        {isMobile &&
          <P>{testimonialItems[activeTestimonialIndex].testimonial}</P>
        }
      </StyledTestimonialContainer>
    </>
  );
};

export default Testimonials;