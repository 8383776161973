import styled from 'styled-components';
import { StyledButton } from "../../../utils/button/button.style";

export const StyledContainer = styled.div`
  // padding-top: 1rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
    color: ${({ theme }) => theme.colors.primaryBlack};
  }

  .steps {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    gap: 0.75rem;
    ${({ theme }) => theme.media.onlyMobile`
      flex-direction: column;
      gap: 2rem;
    `}
  }

  .detailed_step {
    width: 100%;
    padding-top: 2rem;
    transition: all 300ms ease-in-out;
    opacity: 0;
    max-height: 0;

    button {
      margin-left: 82px;
      font-weight: 600;
      font-size: ${({ theme }) => theme.font.size14px};
      line-height: 17px;
      ${({ theme }) => theme.media.onlyMobile`
        margin-left: 0;

        &:last-child {
          margin-top: 1rem;
          color: ${({ theme }) => theme.colors.gray};
          background-color: ${({ theme }) => theme.colors.white};
          border: 1px solid ${({ theme }) => theme.colors.gray};
        }
      `}
    }

    ul {
      counter-reset: section;
      list-style: none;
      padding-left: 0;
    }
    
    li {
      padding: 0 0 10px 0;
      // font-family: 'Gilroy-Bold';
      font-size: ${({ theme }) => theme.font.size16px};
      line-height: 19px;
      color: ${({ theme }) => theme.colors.primaryBlack};
      position: relative;
      overflow: hidden;
      width: 95%;

      > h4 {
        // font-family: 'Gilroy-Bold';
        font-size: ${({ theme }) => theme.font.size16px};
        line-height: 19px;
        color: ${({ theme }) => theme.colors.primaryBlack};
        display: inline-block;
        width: calc(100% - 62px);
        vertical-align: middle;
        margin: 0;
      }

      > p {
        // font-family: 'Gilroy-Regular';
        font-size: ${({ theme }) => theme.font.size15px};
        line-height: 24px;
        color: ${({ theme }) => theme.colors.primaryBlack};
        opacity: 0.75;
        padding-left: 62px;
      }
    }
    
    li:before {
      line-height: 40px;
      content: counter(section);
      counter-increment: section;
      display: inline-block;
      width: 40px;
      height: 40px;
      margin: 0 20px 0 0;
      border: 1px solid #ccc;
      border-radius: 100%;
      text-align: center;
    }

    li:after {
      content: '';
      position: absolute;
      left: 20px;
      top: 40px;
      height: 999px;
      border: 1px solid #EBEBEB;
      border-width: 0 0 0 1px;
    }

    li:last-child::after {
      border-width: 0 0 0;
    }
  }
`;

export const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 34px rgba(160, 160, 160, 0.15);
  border-radius: 12px;
  padding: 0 15px;
  position: relative;
  z-index: 1;

  > div {
    padding: 30px;

    h3 {
      // font-family: 'Gilroy-Medium';
      font-size: ${({ theme }) => theme.font.size24px};
      line-height: 28px;
      letter-spacing: 0.05em;
    }

    p {
      // font-family: 'Gilroy-Regular';
      font-size: ${({ theme }) => theme.font.size15px};
      line-height: 24px;
      color: ${({ theme }) => theme.colors.primaryBlack};
      opacity: 0.75;
    }
  }
`;

export const CardButton = styled(StyledButton)`
  background: #001145;
  border-radius: 0px 0px 12px 12px;
  height: 75px;
  margin-top: -25px;
  position: relative;
  z-index: 0;
  padding-top: 25px;
  width: 100%;
`;