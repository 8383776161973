import styled from 'styled-components';

export const StyledDiv = styled.div`
  background: #161A23;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15%;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size14px};
  line-height: 24px;
  font-feature-settings: 'salt' on, 'liga' off;

  ${({ theme }) => theme.media.onlyMobile`
    flex-direction: column;
    width: 100%;
    padding: 20px 0;
  `}
  
  span {
    :last-child {
      ${({ theme }) => theme.media.onlyMobile`
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
      `}
    }


    color: ${({ theme }) => theme.colors.white};
  
    a {
      color: ${({ theme }) => theme.colors.primary};
    }
  
    svg {
      padding: 0 6px;
    }
  }
`;