import avatar1 from './../../../assets/images/testimonials/avatar-1.webp';
import avatar2 from './../../../assets/images/testimonials/avatar-2.webp';
import avatar4 from './../../../assets/images/testimonials/avatar-4.webp';
import avatar5 from './../../../assets/images/testimonials/avatar-5.webp';
import avatar3 from './../../../assets/images/testimonials/avatar-6.webp';
import avatar7 from './../../../assets/images/testimonials/avatar-7.webp';

export const testimonialItems = [
  {
    avatar: avatar1,
    name: 'John Doe',
    company: 'Google',
    testimonial: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc.Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc.1'
  },
  {
    avatar: avatar2,
    name: 'John Doe',
    company: 'Facebook',
    testimonial: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc.Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc.2'
  },
  {
    avatar: avatar3,
    name: 'John Doe',
    company: 'Curious',
    testimonial: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc.Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc.3'
  },
  {
    avatar: avatar4,
    name: 'John Doe',
    company: 'Walmart',
    testimonial: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc.Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc.4'
  },
  {
    avatar: avatar5,
    name: 'John Doe',
    company: 'KFC',
    testimonial: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc.Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc.5'
  },
  {
    avatar: avatar7,
    name: 'John Doe',
    company: "McDonal's",
    testimonial: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc.Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc.6'
  },
]