import { Link } from "react-router-dom";

import { StyledDiv } from "./secondaryFooter.style";
import { ReactComponent as IGLogo } from './../../../assets/images/socials/ig.svg';
import { ReactComponent as SocialLogo } from './../../../assets/images/socials/social.svg';
import { ReactComponent as TTLogo } from './../../../assets/images/socials/tt.svg';
import { ReactComponent as YTLogo } from './../../../assets/images/socials/yt.svg';


const SecondaryFooter = () => {
  return (
    <StyledDiv>
      <span>All rights reserved © 2022 <Link to='/'>Pharmatiq.com</Link></span>
      <span>
        <IGLogo />
        <SocialLogo />
        <TTLogo />
        <YTLogo />
      </span>
    </StyledDiv>
  );
};

export default SecondaryFooter;