import { BulletContainer } from './bulletList.style';

const BulletList = ({ items }) => {
  return (
    <BulletContainer>
      <div className='bullet_container'>
        {items.map((item, index) => (
          <div key={index} className='bullet_object'>
            {item.icon}
            <div>
              <h3>{item.title}</h3>
              <p>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </BulletContainer>
  )
}

export default BulletList;