import styled from 'styled-components';

export const StyledComponent = styled.div`
  padding: 2rem 0;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.media.onlyMobile`
    flex-direction: column;
    gap: 1rem;
  `}

  >div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    h2 {
      margin-bottom: 0;
      // font-family: 'Gilroy-Medium';
      font-size: ${({ theme }) => theme.font.size64px};
      line-height: 70px;
      ${({ theme }) => theme.media.onlyMobile`
        margin-top: 0.33em;
      `}
    }
  }

  >button {
    ${({ theme }) => theme.media.exceptMobile`
      width: 312px;
    `}
    height: 57px;
  }
`;

export const StyledItemsContainer = styled.div`
  padding: 2rem 0;
  display: grid;
  grid-template-columns: auto auto auto;
  ${({ theme }) => theme.media.onlyMobile`
    grid-template-columns: auto;
  `}
`;

export const StyledBlogItem = styled.div`
  z-index: 1;
  // padding-bottom: 1rem;
  padding: 2rem 1rem;
  ${({ theme }) => theme.media.onlyMobile`
    padding: 2rem 0;
  `}

  h3 {
    color: ${({ theme }) => theme.colors.secondaryBlack};
    // font-family: 'Gilroy-Medium';
    font-size: ${({ theme }) => theme.font.size34px};
    line-height: 48px;

    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: calc(34px * 1.4 * 2);
  }

  .poster {
    width: 100%;
    border-radius: 8px;
  }

  .type_and_date {
    color: ${({ theme }) => theme.colors.blueGray};
    > span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .summary {
    color: ${({ theme }) => theme.colors.secondaryBlack};
    // font-family: 'Gilroy-Regular';
    font-size: ${({ theme }) => theme.font.size18px};
    line-height: 32px;

    ${({ theme }) => theme.media.onlyMobile`
      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: calc(18px * 1.8 * 3);
    `}
  }

  .footer {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;

    .author {
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 1.5rem;

      >img {
        border-radius: 9999px;
      }

      > div {
        padding: 0.5rem;
        padding-left: 0.75rem;

        .position {
          color: #B0B0B0;
          // font-family: 'Manrope';
          font-style: normal;
          font-weight: 500;
          font-size: ${({ theme }) => theme.font.size14px};
          line-height: 19px;
        }
      }
    }

    button {
      ${({ theme }) => theme.media.exceptMobile`
        margin-right: 15px;
      `}
        width: 45px;
        height: 45px;

        > svg {
          padding-left: 0;
        }
    }
  }
`;