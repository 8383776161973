import styled from 'styled-components';

export const StyledMobileMenu = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  row-gap: 4.5%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px 20px;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};

  &, a {
    color: ${({ theme }) => theme.colors.primaryBlack};
    text-decoration: none;
  }

  >button {
    width: 100%;
  }
`;