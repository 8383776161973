import videoPlaceholder from './../../../../assets/images/solutions/video-placeholder.webp';

import { StyledContainer } from './environments.style';

import useMedia from '../../../../hooks/useMedia';
import Donuts from '../../../../utils/donuts/donuts';
import P from '../../../../utils/paragraph/paragraph.style';
import AnimatedHeading from '../../../../utils/animatedHeading/animatedHeading';


const Environments = () => {
  const { isMobile } = useMedia();

  return (
    <>
      {!isMobile && <Donuts style={{ zIndex: '1' }} />}
      <StyledContainer>
        <AnimatedHeading><span>3D</span><br />Environments</AnimatedHeading>
        <P>
          Creating a fully three-dimensional environment allows you to adapt the application to the previously designed visual identification of the product (colors, typography, packshots, created TV commercials).
        </P>
        <P>
          The VR environment can be an extension of the concept or a completely new idea introducing the user to a completely new style.
        </P>
        <P>
          The applications are supplemented with interactive elements such as Quizzes, Movies (2D and 360 degrees), Minigames or the presence of an animated Brand Hero. Each of the environments created so far contains a professionally recorded voice-over as the application's narrator.
        </P>

        {!isMobile && <h5>Sample projects</h5>}
        <img src={videoPlaceholder} alt='' />
      </StyledContainer>
      {!isMobile && <Donuts style={{ zIndex: '1' }} right />}
    </>
  );
};

export default Environments;