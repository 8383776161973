export const theme = {
  colors: {
    white: '#fff',
    black: '#000',
    primary: '#1DB8BA',
    primaryBlack: '#1A202C',
    secondaryBlack: '#12171D',
    blueGray: '#394149',
    gray: '#4D4D4D',
    darkBlue: '#001145',
    lightGray: '#F5F7F8'
  },
  font: {
    size14px: '14px',
    size15px: '15px',
    size16px: '16px',
    size17px: '17px',
    size18px: '18px',
    size20px: '20px',
    size24px: '24px',
    size25px: '25px',
    size28px: '28px',
    size30px: '30px',
    size34px: '34px',
    size38px: '38px',
    size40px: '40px',
    size42px: '42px',
    size46px: '46px',
    size48px: '48px',
    size50px: '50px',
    size56px: '56px',
    size60px: '60px',
    size64px: '64px',
    size96px: '96px',
    size100px: '100px',
    size120px: '120px',
  }
};
