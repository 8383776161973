import Header from '../../containers/solutions/ar/header/header.container';
import InteractivePackshot from '../../containers/solutions/ar/interactivePackshot/interactivePackshot.container';
import InteractivePoster from '../../containers/solutions/ar/interactivePoster/interactivePoster.container';
import InteractiveBrochure from '../../containers/solutions/ar/interactiveBrochure/interactiveBrochure.container';
import EmptySection from '../../containers/solutions/ar/emptySection/emptySection.container';

import ExploreMore from '../../components/expoloreMore/exploreMore.component';

const AugmentedReality = () => {
  return (
    <>
      <Header />
      <InteractivePackshot />
      <InteractivePoster />
      <InteractiveBrochure />
      <EmptySection />
      <ExploreMore
        prevItem={{ title: 'Virtual Reality', href: '/solutions/vr' }}
        nextItem={{ title: 'Mixed Reality', href: '/solutions/mr' }}
      />
    </>
  );
};

export default AugmentedReality;