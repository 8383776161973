import { Link } from "react-router-dom";

import { StyledDiv, LeftBlock, RightBlock, StyledNavContainer } from "./primaryFooter.style";

import { ReactComponent as Logo } from './../../../assets/images/logoWhite.svg';

import useMedia from './../../../hooks/useMedia';
import Button from './../../../utils/button/button';

const PrimaryFooter = () => {
  const { isMobile } = useMedia();
  return (
    <StyledDiv>
      <LeftBlock>
        {isMobile && <Button withArrow onClick={() => window.location = "mailto:office@pharmatiq.pl"}>Estimate a project</Button>}
        <Logo />
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et vulputate rhoncus non sed eget aliquam, viverra tempus quam.</p>
        <div>
          <h3>Company Data:</h3>
          Company Name<br />
          Street 123, City 100-00<br />
          NIP: 399-00-00-00<br />
          tel. + 48 000 000 000<br />
          mail.: example@gmail.com
        </div>
      </LeftBlock>
      <RightBlock>
        <div>
          <h2>PRODUCTS</h2>
          <StyledNavContainer>
            <Link to='/solutions/vr'>Virtual Reality Detailings System</Link>
            <Link to='/solutions/ar'>Augmented Reality</Link>
            <Link to='/solutions/pa'>Product Presentation</Link>
            <Link to='/solutions/vr'>Mixed Reality Sales Solution</Link>
          </StyledNavContainer>
          {!isMobile && <Button withArrow onClick={() => window.location = "mailto:office@pharmatiq.pl"}>Estimate a project</Button>}
        </div>
        <div>
          <h2>MENU</h2>
          <StyledNavContainer>
            <Link to='/'>Home</Link>
            <Link to='/#about'>About</Link>
            <Link to='/#case-study'>Case Studies</Link>
            <Link to='/#case-study'>Solutions</Link>
            <Link to='/#blog'>Blog</Link>
            <Link to='/#testimonials'>Testimonials</Link>
            <Link to='/#contact-us'>Contact</Link>
          </StyledNavContainer>
        </div>
      </RightBlock>
    </StyledDiv>
  );
};

export default PrimaryFooter;