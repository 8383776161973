import dashboardPlaceholder from './../../../../assets/images/solutions/dashboard-placeholder.webp';
import {
  StyledContainer,
  StyledImageContainer,
  StyledContentContainer,
} from './increaseSales.style';

import useMedia from '../../../../hooks/useMedia';
// import Button from '../../../../utils/button/button';
import Donuts from '../../../../utils/donuts/donuts';
import P from '../../../../utils/paragraph/paragraph.style';
import AnimatedHeading from '../../../../utils/animatedHeading/animatedHeading';

const IncreaseSales = () => {
  const { isMobile } = useMedia();

  return (
    <>
      {!isMobile && <Donuts style={{ transform: 'scaleX(-1)', zIndex: '0' }} />}
      <StyledContainer>
        <StyledContentContainer>
          <div>
            <AnimatedHeading><span>Increase</span><br /> sales</AnimatedHeading>
            <P>
              Internal platform to increase sales efficiency of detailing process based on created VR and AR application. Containing geolocation and collecting data of durance, reception and quality of detailings.
            </P>
            {/* <Button withArrow>Learn more</Button> */}
          </div>
        </StyledContentContainer>
        <StyledImageContainer>
          <img src={dashboardPlaceholder} alt='' />
        </StyledImageContainer>
      </StyledContainer>
    </>
  );
}

export default IncreaseSales;