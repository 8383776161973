import vrPerformance from './../../../../assets/images/solutions/vr-performance.webp';
import vrImmersion from './../../../../assets/images/solutions/vr-immersion.webp';

import { StyledContainer } from './interactivePoster.style';

import AnimatedHeading from '../../../../utils/animatedHeading/animatedHeading';
import BulletList from '../../../../components/bulletList/bulletList.component';
import P from '../../../../utils/paragraph/paragraph.style';
import features from './featuresList';

const InteractivePoster = () => {

  return (
    <>
      <StyledContainer>
        <div>
          <img src={vrImmersion} alt='' />
          <img src={vrPerformance} alt='' />
        </div>
        <div>
          <AnimatedHeading side='right'><span>Interactive</span><br /> Poster</AnimatedHeading>
          <P>
            Creating a fully three-dimensional environment allows you to adapt the application to the previously designed visual identification of the product (colors, typography, packshots, created TV commercials).
          </P>
          <BulletList items={features} />
        </div>
      </StyledContainer>
    </>
  );
};

export default InteractivePoster;