import companiesImage from './../../assets/images/testimonials/companies.webp';

import { StyledCompaniesContainer } from './promotedBy.style';

const PromotedBy = ({ style }) => (
  <StyledCompaniesContainer style={style}>
    Trusted by forward-thinking software teams around the world
    <img src={companiesImage} alt='' />
  </StyledCompaniesContainer>
);

export default PromotedBy;