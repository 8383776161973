import { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";

import { ReactComponent as EllipseTopLeft } from './../../../assets/images/blogs/ellipse-top-left.svg';
import { ReactComponent as EllipseBottomRight } from './../../../assets/images/blogs/ellipse-bottom-right.svg';
import { StyledHeaderContainer, StyledContentContainer, StyledBlogItem } from './blogs.style';

import axios from '../../../axios';
import useMedia from '../../../hooks/useMedia';
import P from '../../../utils/paragraph/paragraph.style';
import AnimatedHeading from '../../../utils/animatedHeading/animatedHeading';
import NavigationalButton from '../../../utils/navigationalButton/navigationalButton';


const Blogs = () => {
  const { isMobile } = useMedia();
  const [blogItems, setBlogItems] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('articles', {
      params: {
        limit: 2
      }
    })
      .then(response => {
        setBlogItems(response.data.data);
      })
      .catch((reason) => console.error(reason));
  }, []);

  useEffect(() => {
    setLoading(blogItems.length === 0)
  }, [blogItems]);

  const renderBlogItems = (isMobile) => (
    blogItems.map((item, i) => {
      return (
        <StyledBlogItem key={i}>
          <img className='poster' src={item.headerImage.thumb.url} alt='' />
          <p className='type_and_date'>
            <span>{item.categories[0] + ' '}&#8226;</span>{' '}
            {item.createdAt}
          </p>
          <h4>{item.title}</h4>
          <p className='summary'>{item.bodyShort}</p>
          <div className='footer'>
            <div className='author'>
              <img src={item.author.avatar.webp.url} alt='' />
              <div>
                <span>{`${item.author.firstName} ${item.author.lastName}`}</span><br />
                <span className='position'>{item.author.occupiedPosition}</span>
              </div>
            </div>
            <NavigationalButton withArrow navigateTo={`/blog/${item.slug}`}>{isMobile ? '' : 'Read more '}</NavigationalButton>
          </div>
        </StyledBlogItem>
      )
    })
  );

  return (
    <>
      <StyledHeaderContainer id="blog">
        <div>
          <EllipseTopLeft style={
            isMobile ?
              { position: 'absolute', top: '0', left: '-100px', zIndex: '-1' }
              :
              { position: 'absolute', top: '0', left: '0', zIndex: '-1' }
          } />
          <EllipseBottomRight style={{ position: 'absolute', bottom: '0', right: `${isMobile ? '-250px' : '0'}`, zIndex: '-1' }} />
        </div>
        <div className='header_container'>
          <h3>Blog</h3>
          <AnimatedHeading type='h2'>Get Started Now,<br />Pick a Plan Later</AnimatedHeading>
          <P>We believe that the pharmaceutical industry does not necessarily need more products, it needs better solutions</P>
          <NavigationalButton navigateTo='/blog'>View blog</NavigationalButton>
        </div>
      </StyledHeaderContainer>
      <StyledContentContainer isLoading={isLoading}>
        {renderBlogItems(isMobile)}
        <ClipLoader loading={isLoading} size={80} color='#1DB8BA' />
      </StyledContentContainer>
    </>
  );
};

export default Blogs;