import styled from 'styled-components';

export const StyledContainer = styled.div`
  margin-top: -110px;
  position: relative;
  overflow: hidden;
  ${({ theme }) => theme.media.onlyMobile`
    padding-top: 45px;
    padding-bottom: 0;
  `}
`;

export const StyledShareContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 5%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  z-index: 9999;

  svg > path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledDownScrollContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 5%;
  transform: rotate(90deg);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  cursor: pointer;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size14px};
  line-height: 16px;
  color: #D3D3D3;

  > svg {
    transform: rotate(270deg);
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  justify-content: center;
  height: inherit;
  position: relative;
  z-index: 2;
  flex-direction: row;
  ${({ theme }) => theme.media.onlyMobile`
    flex-direction: column;
  `}

  >div{
    position: relative;

    :first-child {
      padding-top: 9rem;
      ${({ theme }) => theme.media.exceptMobile`
        width: 60%;
        padding-bottom: 10rem;
      `}
      ${({ theme }) => theme.media.onlyMobile`
        padding-top: 3rem;
      `}
    }

    :last-child {
      ${({ theme }) => theme.media.exceptMobile`
        width: 40%;
      `}

      img {
        ${({ theme }) => theme.media.exceptMobile`
        position: absolute;
        bottom: 0;
        max-height: 100%;
        max-width: 710px;
        width: 153%;
        `}
        ${({ theme }) => theme.media.onlyMobile`
          position: relative;
        `}
      }
    }
  }

  h1 {
    font-size: ${({ theme }) => theme.font.size100px};
    line-height: 94px;
    color: ${({ theme }) => theme.colors.primaryBlack};
    width: min-content;

    ${({ theme }) => theme.media.onlyMobile`
      font-size: ${({ theme }) => theme.font.size46px};
      line-height: 54px;
    `}
  }

  p {
    max-width: 560px;
    color: ${({ theme }) => theme.colors.gray};
  }

  div {
    ${({ theme }) => theme.media.onlyMobile`
      display: flex;
      flex-direction: column;
      gap: 20px;
    `}

    button {
      display: inline-flex;
    }

    a {
      padding-left: 30px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: ${({ theme }) => theme.font.size16px};
      line-height: 16px;
      ${({ theme }) => theme.media.onlyMobile`
        padding-left: 0;
        text-align: center;
      `}
    }
  }
`;

export const WhyUsContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  position: static;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  ${({ theme }) => theme.media.onlyMobile`
    text-align: left;
  `}

  .content {
    padding: 150px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      // font-family: 'Gilroy-Regular';
      font-size: ${({ theme }) => theme.font.size42px};
      line-height: 60px;
      ${({ theme }) => theme.media.onlyMobile`
        font-size: ${({ theme }) => theme.font.size24px};
        line-height: 36px;
      `}
    }

    ${({ theme }) => theme.media.exceptMobile`
      p {
        max-width: 600px;
      }
    `}
  }
`;