import { useNavigate } from "react-router-dom";

import Button from "../button/button";

const NavigationalButton = (props) => {
  let navigate = useNavigate();
  return (
    <Button onClick={() => navigate(props.navigateTo)} {...props}>{props.children}</Button>
  );
};

export default NavigationalButton;