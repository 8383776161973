import Header from '../../containers/blog/header/header.container';
import Content from '../../containers/blog/content/content.container';

const Index = () => {
  return (
    <>
      <Header />
      <Content />
    </>
  );
};

export default Index;