import { StyledContainer, StyledAuthorContainer, StyledHeaderImage, StyledScrollProgressBar } from './header.style';
import { useState, useEffect } from 'react';
import useMedia from '../../../hooks/useMedia';

import categoryName from '../../../utils/categoryName/categoryName';

const Home = ({ item }) => {
  const { isMobile } = useMedia();
  const [isLoading, setLoading] = useState(true);
  const [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    setLoading(item && Object.keys(item).length === 0)
  }, [item]);

  useEffect(() => {
    const onScroll = (e) => {
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      setScrollWidth(parseInt(100 * winScroll / height));
    };


    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  })

  if (isLoading) return null;
  return (
    <>
      <StyledScrollProgressBar width={scrollWidth} />
      <StyledContainer>
        {isMobile && <p className='type_and_date'>
          <span>{categoryName(item.categories[0].toLowerCase()) + ' '}&#8226;</span>{' '}
          {item.createdAt}
        </p>}
        <h1>{item.title}</h1>
        <StyledAuthorContainer>
          <img src={item.author.avatar.webp.url} alt='' />
          <div>
            <span>{`${item.author.firstName} ${item.author.lastName}`}</span><br />
            <span className='position'>{item.author.occupiedPosition}</span>
          </div>
          {!isMobile && <>
            <div style={{ border: '1px solid #001860', height: '64px', padding: '0', margin: '0 1.5rem' }}></div>
            <p className='type_and_date'>
              <span>{categoryName(item.categories[0].toLowerCase()) + ' '}&#8226;</span>{' '}
              {item.createdAt}
            </p>
          </>}
        </StyledAuthorContainer>
      </StyledContainer>
      <StyledHeaderImage>
        <img src={item.headerImage?.largeWebp?.url} alt='' />
      </StyledHeaderImage>
    </>
  );
};

export default Home;