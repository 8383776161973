import mobileImage from './../../../../assets/images/solutions/app_pharma.webp';
import decoDonuts from './../../../../assets/images/solutions/deco-vector-2.webp';

import { StyledContainer } from './interactivePackshot.style';
import features from './featuresList';

import P from '../../../../utils/paragraph/paragraph.style';
import AnimatedHeading from '../../../../utils/animatedHeading/animatedHeading';
import BulletList from '../../../../components/bulletList/bulletList.component';

const InteractivePackshot = () => {

  return (
    <>
      <StyledContainer>
        <div>
          <AnimatedHeading><span>Interactive</span><br /> Packshot</AnimatedHeading>
          <P>
            Internal platform to increase sales efficiency of detailing process based on created VR and AR application. Containing geolocation and collecting data of durance, reception and quality of detailings.
          </P>
          <BulletList items={features} />
        </div>
        <div>
          <img src={mobileImage} alt='' />
          <img src={decoDonuts} alt='' />
        </div>
      </StyledContainer>
    </>
  );
};

export default InteractivePackshot;