import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  ${({ theme }) => theme.media.exceptMobile`  
    padding: 5rem 0;
    max-width: 50%;
    margin: 0 auto;
  `}
  position: relative;
`;

export const StyledShareContainer = styled.div`
${({ theme }) => theme.media.exceptMobile`  
  position: absolute;
  top: 5rem;
  left: -30%;
  `}


  h4 {
    margin-top: 0;
    // font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #394149;
  }

  >div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 45px;
    ${({ theme }) => theme.media.onlyMobile`
      flex-direction: row;
      font-size: 0;
      justify-content: space-between;
    `}

    button > svg {
      padding-right: 10px;
    }
  }
`;

export const StyledTag = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 999px;
  padding: 10px 24px;
`;

export const StyledTagsContainer = styled.div`
  ${({ theme }) => theme.media.exceptMobile`  
    padding: 1rem 405px 2.5rem;
  `}
  ${({ theme }) => theme.media.onlyMobile`
    padding-bottom: 3rem;
  `}
  >h4 {
    // font-family: 'Gilroy-Medium';
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.secondaryBlack};
  }
  >div {
    display: flex;
    gap: 10px;

    ${({ theme }) => theme.media.onlyMobile`
      flex-wrap: wrap;
    `}
  }
`;

export const StyledBlogItem = styled.div`
  ${({ theme }) => theme.media.exceptMobile`
    max-width: 49%;
  `}
  z-index: 1;
  padding-bottom: 1rem;

  h1 {
    color: ${({ theme }) => theme.colors.secondaryBlack};
    // font-family: 'Gilroy-Medium';
    font-size: ${({ theme }) => theme.font.size34px};
    line-height: 48px;
  }

  .poster {
    width: 100%;
    border-radius: 8px;
  }

  .type_and_date {
    color: ${({ theme }) => theme.colors.blueGray};
    > span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .summary {
    color: ${({ theme }) => theme.colors.secondaryBlack};
    // font-family: 'Gilroy-Regular';
    font-size: ${({ theme }) => theme.font.size18px};
    line-height: 32px;
  }

  .footer {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;

    .author {
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 1.5rem;

      >img {
        border-radius: 9999px;
      }

      > div {
        padding: 0.5rem;
        padding-left: 0.75rem;

        .position {
          color: #B0B0B0;
          // font-family: 'Manrope';
          font-style: normal;
          font-weight: 500;
          font-size: ${({ theme }) => theme.font.size14px};
          line-height: 19px;
        }
      }
    }

    button {
      ${({ theme }) => theme.media.exceptMobile`
        margin-right: 15px;
      `}
      ${({ theme }) => theme.media.onlyMobile`
        width: 45px;
        height: 45px;

        > svg {
          padding-left: 0;
        }
      `}
    }
  }
`;

export const StyledContentContainer = styled.div`
  >h2 {
    // font-family: 'Gilroy-Medium';
    font-size: ${({ theme }) => theme.font.size96px};
    line-height: 94px;
    color: ${({ theme }) => theme.colors.primaryBlack};
    >span {
      color: ${({ theme }) => theme.colors.primary};
    }

    ${({ theme }) => theme.media.onlyMobile`
      font-size: ${({ theme }) => theme.font.size64px};
    `}
  }
  >div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: ${({ isLoading }) => isLoading ? 'center' : 'space-between'};
    padding-bottom: 5rem;
    padding-top: 2rem;
    ${({ theme }) => theme.media.onlyMobile`
      flex-direction: column;
      ${({ isLoading }) => isLoading && css`
        align-items: center;
      `}
    `}
  }
`;