import styled from 'styled-components';

export const StyledCompaniesContainer = styled.div`
  text-align: center;
  padding-bottom: 5rem;
  color: ${({ theme }) => theme.colors.gray};
  // font-family: 'Gilroy-Medium';
  font-size: ${({ theme }) => theme.font.size16px};
  line-height: 27px;
  overflow: hidden;
  
  img {
    padding-top: 3rem;
    width: 100%;
    ${({ theme }) => theme.media.onlyMobile`
      height: 60px;
      width: auto;
      transform: translate(-40%, 0) scale(0.65);
    `}
  }
`;