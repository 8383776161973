import Header from '../../containers/index/header/header.container';
import Solutions from '../../containers/index/solutions/solutions.container';
import Process from './../../containers/index/process/process.container';
import Industries from '../../containers/index/industries/industries.container';
import Blogs from '../../containers/index/blogs/blogs.container';
import Testimonials from '../../containers/index/testimonials/testimonials.container';
import ContactUs from '../../containers/index/contactUs/contactUs.container';

import PromotedBy from '../../components/promotedBy/promotedBy.component';

const Index = () => {
  return (
    <>
      <Header />
      <Solutions />
      <Process />
      <Industries />
      <Blogs />
      <Testimonials />
      <PromotedBy />
      <ContactUs />
    </>
  );
};

export default Index;