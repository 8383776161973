import styled from 'styled-components';

export const StyledContainer = styled.div`
  margin-top: -110px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  ${({ theme }) => theme.media.onlyMobile`
    padding: 45px 0 0 0;
  `}

  >h1 {
    padding: 10rem 0 3rem;
    margin: 0;
    // font-family: 'Gilroy-Medium';
    font-size: ${({ theme }) => theme.font.size48px};
    line-height: 94px;

    ${({ theme }) => theme.media.onlyMobile`
      padding: 6.5rem 25px 6.5rem;
    `}
  }
`;