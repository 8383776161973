import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  padding-top: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => theme.media.onlyMobile`
    padding-top: 3rem;
    flex-direction: column;
  `}

  > div {
    ${({ theme }) => theme.media.exceptMobile`
      width: 50%;
    `}

    :last-child {
      align-self: end;
      z-index: 0;

      img:first-child {
        position: relative;
        width: 125%;
        ${({ theme }) => theme.media.exceptMobile`
          bottom: -1rem;
          left: -10rem;
        `}
        ${({ theme }) => theme.media.onlyMobile`
          vertical-align: bottom;
          width: 100%;
        `}
      }

      img:last-child {
        position: absolute;
        z-index: -1;
        ${({ theme }) => theme.media.exceptMobile`
          bottom: -20rem;
          right: 0;
        `}
        ${({ theme }) => theme.media.onlyMobile`
          transform: scale(0.77) rotate(-90deg);
          width: 100%;
          bottom: -4rem;
          right: 4rem;
        `}
      }
    }

    h2 {
      margin-top: 0;
      ${({ theme }) => theme.media.onlyMobile`
        font-size: ${({ theme }) => theme.font.size64px};
        line-height: 75px;
      `}
    }

    > p {
      color: ${({ theme }) => theme.colors.gray};
      padding-bottom: 2rem;
      ${({ theme }) => theme.media.onlyMobile`
        font-size: ${({ theme }) => theme.font.size20px};
        line-height: 36px;
      `}
    }
  }
  
  span { 
    color: ${({ theme }) => theme.colors.primary};
  }
  
  
  .bullet_object {
    p {
      color: ${({ theme }) => theme.colors.gray};
      ${({ theme }) => theme.media.exceptMobile`
        max-width: 60%;
      `}
    }
  }
  `;
