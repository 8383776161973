import { ReactComponent as LeftDonutOne } from './../../assets/images/donut-3.svg';
import { ReactComponent as LeftDonutTwo } from './../../assets/images/donut-4.svg';

import { StyledDonuts } from './donuts.style';

const Donuts = ({ right, style, scale, decorLeftPos }) => {
  const scaling = scale || 1;
  const distance = 75 * scaling;
  const top = 180;
  const scaledVector = (1 - scaling) * 30;
  const leftPos = decorLeftPos || 0;

  return (
    <StyledDonuts right={right} style={style}>
      <LeftDonutOne style={{ position: 'absolute', left: `${leftPos}px`, top: `-${top}px`, transform: `scale(${scaling})` }} />
      <LeftDonutTwo style={{ position: 'absolute', left: `${leftPos + scaledVector}px`, top: `-${top + distance}px`, transform: `scale(${scaling})` }} />
    </StyledDonuts>
  );
}

export default Donuts;