import { useState } from "react";
import { PopupModal } from "react-calendly";

import Button from "../../utils/button/button";

const ScheduleMeetingButton = ({ children, externalCallback }) => {
  const [isOpen, setOpen] = useState(false);
  const dispatchexternalCallback = () => {
    if (typeof externalCallback === 'function') {
      externalCallback();
    }
  };

  return (
    <>
      <Button onClick={() => {
        dispatchexternalCallback();
        setOpen(true);
      }}>{children}</Button>
      <PopupModal
        url="https://calendly.com/jakub-burdajewicz/pharmatiq-live-demo?hide_gdpr_banner=1"
        rootElement={document.getElementById("root")}
        onModalClose={() => setOpen(false)}
        open={isOpen}
      />
    </>
  );
};

export default ScheduleMeetingButton;