import styled from 'styled-components';

import background from './../../../../assets/images/solutions/donut-background.svg';

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBlack};
  background-image: url(${background});
  background-size: cover;
  background-position-y: 27rem;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 20rem;
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  ${({ theme }) => theme.media.onlyMobile`
    padding-top: 5rem;
    h2 {
      font-size: ${({ theme }) => theme.font.size56px};
      line-height: 76px;
    }
  `}
  
  span {
    color: ${({ theme }) => theme.colors.primary};
  }

  h3 {
    // font-family: 'Gilroy-Medium';
    font-weight: 400;
    font-size: ${({ theme }) => theme.font.size34px};
    line-height: 48px;
    ${({ theme }) => theme.media.onlyMobile`
      font-size: ${({ theme }) => theme.font.size25px};
      line-height: 33px;
    `}
  }
  
  p {
    text-align: left;
    ${({ theme }) => theme.media.exceptMobile`
      max-width: 60%;
    `}
    ${({ theme }) => theme.media.onlyMobile`
      font-size: ${({ theme }) => theme.font.size20px};
      line-height: 34px;
    `}
  }

  h5 {
    // font-family: 'Gilroy-Bold';
    font-weight: 400;
    font-size: ${({ theme }) => theme.font.size18px};
    line-height: 21px;
    color: ${({ theme }) => theme.colors.primary};
    margin-top: 5rem;
  }

  img {
    position: relative;
    z-index: 2;
  }
`;

export const StyledAdvantagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h5 {
    color: ${({ theme }) => theme.colors.white};
    padding-bottom: 2rem;
    // font-family: 'Gilroy-Medium';
    font-weight: 400;
    font-size: ${({ theme }) => theme.font.size24px};
    line-height: 27px;
    ${({ theme }) => theme.media.onlyMobile`
      font-size: ${({ theme }) => theme.font.size18px};
      line-height: 27px;
    `}
  }

  > div {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    ${({ theme }) => theme.media.onlyMobile`
      flex-direction: column;
      gap: 3rem;
    `}

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        color: ${({ theme }) => theme.colors.white};
        padding-top: 2.5rem;
        text-align: center;
        // font-family: 'Gilroy-Bold';
        font-weight: 400;
        font-size: ${({ theme }) => theme.font.size24px};
        line-height: 28px;
      }
    }
  }
`;

export const StyledImageContainer = styled.div`
  padding-top: 7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.media.onlyMobile`
    flex-direction: column;
    gap: 3rem;
  `}

  img {
    width: 49%;
    background-color: ${({ theme }) => theme.colors.darkBlue};
    border-radius: 12px;
    ${({ theme }) => theme.media.onlyMobile`
      width: 100%;
    `}
  }
`;