import styled from 'styled-components';
import bgVector from './../../../../assets/images/solutions/bg-vector.svg';
import background from './../../../../assets/images/solutions/donut-background.svg';

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBlack};
  background-image: url(${bgVector}), url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  
  span {
    color: ${({ theme }) => theme.colors.primary};
  }

  h2 {
    text-align: center;
    padding-top: 4rem;
    ${({ theme }) => theme.media.onlyMobile`
      font-size: ${({ theme }) => theme.font.size60px};
      line-height: 75px;
    `}
  }

  img {
    position: relative;
    z-index: 2;
  }
`;

export const StyledAdvantagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.media.exceptMobile`
    padding: 0 5rem;
  `}

  .bullet_container {
    display: grid;
    grid-template-columns: auto auto auto;
    padding-top: 2rem;
    text-align: left;
    ${({ theme }) => theme.media.onlyMobile`
      grid-template-columns: auto;
    `}
  }
`;

export const StyledImageContainer = styled.div`
  padding-top: 7rem;
  padding-bottom: 7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  > div {
    width: 100%;

    > img { 
      width: 100%; 
    }
  }

  img {
    border-radius: 24px;
  }

  > img {
    opacity: 0.15;
    position: absolute;
    width: 75%;

    :first-child {
      transform: translate(-105%, 0px);
    }

    :last-child {
      right: 0;
      transform: translate(105%, 0px);
    }
  }
`;