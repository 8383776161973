import { useState, useRef, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";

import { SubmenuContainer } from "./submenuItem.style";

import { ReactComponent as DownArrow } from './../../../assets/images/chevron-down.svg';


export const SubmenuItem = ({ item, dark, isMobile }) => {
  let ref = useRef();
  const [submenuShow, setSubmenuShow] = useState(false);
  const solutions = item?.submenu;

  const renderSolutionSubmenu = () => (
    <SubmenuContainer style={{ height: submenuShow ? 'auto' : '0' }} dark={dark} showIt={submenuShow}>
      {
        solutions.map((item, index) => (
          <Fragment key={index}>
            {index !== 0 &&
              <div className="submenuDivider" key={`divider${index}`} />
            }
            <NavLink to={item.href} key={`Submenu${index}`}>{item.title}</NavLink>
          </Fragment>
        ))
      }
    </SubmenuContainer>
  );

  useEffect(() => {
    if (!isMobile) {
      const handler = (event) => {
        if (submenuShow && ref.current && !ref.current.contains(event.target)) {
          setSubmenuShow(false);
        }
      };
      document.addEventListener("mousedown", handler);
      document.addEventListener("touchstart", handler);
      return () => {
        document.removeEventListener("mousedown", handler);
        document.removeEventListener("touchstart", handler);
      };
    }
  }, [submenuShow, isMobile]);

  return (
    <div
      ref={ref}
      style={{ cursor: 'pointer', whiteSpace: 'nowrap', position: 'relative' }}
      onClick={() => setSubmenuShow(!submenuShow)}
    >
      {item.title}
      <DownArrow style={{ paddingLeft: '5px' }} />
      {renderSolutionSubmenu()}
    </div>
  );
}