import { StyledCheckboxContainer } from "./checkboxInput.style";

const CheckboxInput = ({ inputId, label, inputName, value, onChange }) => {
  return (
    <StyledCheckboxContainer>
      <input type='checkbox' id={inputId} name={inputName} value={value} onChange={onChange} />
      <span className="checkmark"></span>
      {label}
    </StyledCheckboxContainer>
  )
};

export default CheckboxInput;