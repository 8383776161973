import styled from 'styled-components';

export const StyledHeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  padding-top: 10rem;
  padding-bottom: 10rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.primaryBlack};
  ${({ theme }) => theme.media.onlyMobile`
    padding-top: 3rem;
    padding-bottom: 3rem;
    flex-direction: column-reverse;
  `}
  
  > div {
    ${({ theme }) => theme.media.exceptMobile`
      max-width: 75%;
    `}
    margin: 0 auto;
    text-align: left;
    
    h2 {
      margin-top: 0;
      margin-bottom: 1rem;
      text-align: center;
      ${({ theme }) => theme.media.onlyMobile`
        font-size: ${({ theme }) => theme.font.size64px};
        line-height: 75px;
      `}
    }
    
    > p {
      text-align: center;
      ${({ theme }) => theme.media.onlyMobile`
        font-size: ${({ theme }) => theme.font.size20px};
        line-height: 36px;
      `}
    }
    
    span { 
      color: ${({ theme }) => theme.colors.primary};
      line-height: 112px;
    }
  }
  `;

export const StyledContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  position: relative;
  margin-top: 3rem;
  color: ${({ theme }) => theme.colors.white};
  
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.media.onlyMobile`
    flex-direction: column-reverse;
    padding: 0;
  `}

  > div {
    ${({ theme }) => theme.media.exceptMobile`
      width: 125%;
      align-self: flex-end;
    `}

    :last-child {
      width: auto;
      align-self: center;
      z-index: 0;
      overflow: hidden;
      ${({ theme }) => theme.media.exceptMobile`
        padding-left: 4rem;
      `}
      ${({ theme }) => theme.media.onlyMobile`
        padding: 0 25px;
      `}
    }
    > img {
      width: 100%;
      position: relative;
      bottom: -1rem;
      ${({ theme }) => theme.media.exceptMobile`
        margin-top: -15rem;
      `}
    }

    h2 {
      font-size: ${({ theme }) => theme.font.size48px};
      line-height: 56px;
      ${({ theme }) => theme.media.exceptMobile`
        margin-top: 0;
      `}
    }
  }
`;
