import styled from 'styled-components';
import background from './../../../../assets/images/solutions/pa-background-image.webp';

export const StyledContainer = styled.div`
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  ${({ theme }) => theme.media.onlyMobile`
    flex-direction: column;
    padding: 0;
  `}

  > div {
    ${({ theme }) => theme.media.exceptMobile`
      background-image: url(${background});
      background-size: cover;
      border-radius: 12px;
    `}
    background-color: ${({ theme }) => theme.colors.primaryBlack};
    width: 100%;
  }
`;

export const StyledImageContainer = styled.div`
  position: relative;
  height: 715px;

  img {
    position: absolute;
    left: 0;
  }
`;

export const StyledContentContainer = styled.div`
  > div {
    padding: 9rem 65% 9rem 4rem;
    ${({ theme }) => theme.media.onlyMobile`
      padding: 3rem 25px;
    `}

    color: ${({ theme }) => theme.colors.white};
    > h2 {
      // font-family: 'Gilroy-Medium';
      font-weight: 400;
      font-size: ${({ theme }) => theme.font.size48px};
      line-height: 56px;
      margin-top: 0;
      margin-bottom: 0;
    }

    > p {
      line-height: 23px;
      ${({ theme }) => theme.media.onlyMobile`
        line-height: 36px;
      `}
    }

    >button {
      margin-top: 2rem;
    }
  }

  ${({ theme }) => theme.media.onlyMobile`
  >img {
    width: 200%;
    transform: translate(-40%, 30%);
    margin-top: -5rem;
    vertical-align: bottom;
  }
  `}
`;