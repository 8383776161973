import styled, { css } from 'styled-components';
import Paragraph from '../../../utils/paragraph/paragraph.style';

export const StyledTestimonialContainer = styled.div`
  position: relative;
  padding-bottom: 5rem;

  > svg {
    position: absolute;
    left: 0;
    width: 100%;
    ${({ theme }) => theme.media.onlyMobile`
      top: 200px;
    `}
  }

  h2 {
    padding-top: 4rem;
    text-align: center;
    position: relative;

    > span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const StyledCloudContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 75%;
  position: relative;

  .column {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.middle {
      justify-content: flex-end;
      ${({ theme }) => theme.media.exceptMobile`
        padding-top: 8rem;
      `}
      transition: opacity 500ms ease-in-out;
      opacity: 1;

      h3 {
        // font-family: 'Gilroy-Medium';
        font-size: ${({ theme }) => theme.font.size18px};
        line-height: 30px;
        color: ${({ theme }) => theme.colors.primaryBlack};
        margin-bottom: 0.5rem;
      }

      h4 {
        // font-family: 'Gilroy-Regular';
        font-size: ${({ theme }) => theme.font.size15px};
        line-height: 18px;
        opacity: 0.5;
        margin-top: 0;
      }
    }

    > div {
      position: relative;
      height: 33%;
      width: 100%;

      >svg, >div {
        position: absolute;
      }
    }
  }
`;

export const StyledAvatar = styled.img`
  cursor: pointer;
  border-radius: 9999px;

  ${({ active }) => active && css`
    box-shadow: inset 0 0 20px 20px ${({ theme }) => theme.colors.primary}, 0 0 20px 10px ${({ theme }) => theme.colors.primary};
  `}
`;

export const P = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  text-align: justify;
`;