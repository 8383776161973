import Header from '../../containers/solutions/pa/header/header.container';
import KeyFeatures from '../../containers/solutions/pa/keyFeatures/keyFeatures.container';
import InternalPlatform from '../../containers/solutions/pa/internalPlatform/internalPlatform.container';
import IncreaseSales from '../../containers/solutions/pa/increaseSales/increaseSales.container';
import Banner from '../../containers/solutions/pa/banner/banner.container';
import AppFeatures from '../../containers/solutions/pa/appFeatures/appFeatures.container';
import StatisticsAndReports from '../../containers/solutions/pa/statisticsAndReports/statisticsAndReports.container';

import ExploreMore from '../../components/expoloreMore/exploreMore.component';
import PromotedBy from '../../components/promotedBy/promotedBy.component';

const PlatformAnalytical = () => {
  return (
    <>
      <Header />
      <KeyFeatures />
      <InternalPlatform />
      <IncreaseSales />
      <Banner />
      <AppFeatures />
      <PromotedBy style={{ paddingTop: '2rem' }} />
      <StatisticsAndReports />
      <ExploreMore
        prevItem={{ title: 'Mixed Reality', href: '/solutions/mr' }}
        nextItem={{ title: 'Virtual Reality', href: '/solutions/vr' }}
      />
    </>
  );
};

export default PlatformAnalytical;