import Header from '../../containers/solutions/vr/header/header.container';
import Explanation from '../../containers/solutions/vr/explanation/explanation.container';
import ProductionProcess from '../../containers/solutions/vr/productionProcess/productionProcess.container';
import Environments from '../../containers/solutions/vr/environments/environments.container';
import VirtualTours from '../../containers/solutions/vr/virtualTours/virtualTours.container';

import ExploreMore from '../../components/expoloreMore/exploreMore.component';

const VirtualReality = () => {
  return (
    <>
      <Header />
      <Explanation />
      <ProductionProcess />
      <Environments />
      <VirtualTours />
      <ExploreMore
        prevItem={{ title: 'Platform Analytical', href: '/solutions/pa' }}
        nextItem={{ title: 'Augmented Reality', href: '/solutions/ar' }}
      />
    </>
  );
};

export default VirtualReality;