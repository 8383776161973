import axios from "axios";

const instance = axios.create({
  baseURL: `https://api.pharmatiq.pl/api/v1/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 15000
});

export default instance;