import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import axios from '../../axios';

import useLocationChange from '../../hooks/useLocationChange';
import { NavbarStyleContext } from '../../context/navbarStyle/navbarStyle';

import Header from '../../containers/blogShow/header/header.container';
import Content from '../../containers/blogShow/content/content.container';

const Index = () => {
  const [item, setItem] = useState({});
  const { setInitialDark, setBgColor } = useContext(NavbarStyleContext);
  let { articleId } = useParams();

  useLocationChange((location) => {
    setTimeout(() => {
      setInitialDark(true);
      setBgColor('');
    }, 100);
  });

  useEffect(() => {
    axios.get(`articles/${articleId}`)
      .then((response) => setItem(response.data.data))
      .catch((reason) => console.error(reason));
  }, [articleId]);

  return (
    <>
      <Header item={item} />
      <Content item={item} />
    </>
  );
};

export default Index;