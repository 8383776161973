import { StyledContainer } from './header.style';

import BlogHeader from '../../../components/blogHeader/blogHeader.component';

const Home = ({ title, category }) => {

  return (
    <>
      <StyledContainer>
        <h1>{title}</h1>
        <BlogHeader activeCategory={category} />
      </StyledContainer>
    </>
  );
};

export default Home;