import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primaryBlack};
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
  overflow: hidden;
  ${({ theme }) => theme.media.exceptMobile`
    text-align: center;
    > p {
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  `}
  ${({ theme }) => theme.media.onlyMobile`
    padding-top: 3rem;
    padding-bottom: 3rem;
  `}

  h2 {
    margin-top: 0;
    ${({ theme }) => theme.media.onlyMobile`
      font-size: ${({ theme }) => theme.font.size64px};
      line-height: 75px;
    `}
  }

  .bullet_container {
    display: grid !important;
    padding-top: 2rem;
    text-align: left;
    grid-template-columns: auto auto auto;
    ${({ theme }) => theme.media.onlyMobile`
      grid-template-columns: auto;
    `}
  }
`;