import { createGlobalStyle } from "styled-components";
import interFont from './assets/fonts/Inter-Regular.ttf'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    src: url(${interFont}) format('woff');
    font-display: fallback;
  }
`;

export default GlobalStyle;