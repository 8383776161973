import { ReactComponent as ArrowDown } from './../../../../assets/images/down-arrow.svg';
import { ReactComponent as Circle } from './../../../../assets/images/circle.svg';

import {
  StyledContainer,
  StyledContentContainer,
  StyledDownScrollContainer
} from './header.style';

import useMedia from '../../../../hooks/useMedia';
import P from '../../../../utils/paragraph/paragraph.style';
import scrollDown from "../../../../utils/scrollDown/scrollDown";
import NavigationalButton from "../../../../utils/navigationalButton/navigationalButton";

const Header = () => {
  const { isMobile } = useMedia();

  return (
    <>
      <StyledContainer>
        <StyledContentContainer>
          <div>
            <h1>Platform Analytical</h1>
            <P>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc. Vitae, a dictum nisi turpis sagittis.
            </P>
            <NavigationalButton navigateTo='/#contact-us'>Request demo</NavigationalButton>
            {!isMobile &&
              <>
                <StyledDownScrollContainer>
                  <Circle />
                  <div onClick={scrollDown}>
                    Scroll down <ArrowDown />
                  </div>
                </StyledDownScrollContainer>
              </>
            }
          </div>
        </StyledContentContainer>
      </StyledContainer>
    </>
  );
};

export default Header;