import { useState } from 'react';

import { StyledContainer, StyledLink } from './blogHeader.style';

const CustomLink = (props) => {
  const [isHover, setHover] = useState(false);
  return (
    <StyledLink
      className={isHover ? 'hovered' : ''}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    />
  )
}

const BlogHeader = ({ activeCategory }) => {
  const isActive = (name) => {
    return activeCategory === name ? 1 : 0;
  }
  return (
    <StyledContainer>
      <CustomLink active={isActive('vr')} to='/blog/category/vr'><span>Virtual Reality</span></CustomLink>
      <CustomLink active={isActive('ar')} to='/blog/category/ar'><span>Augmented Reality</span></CustomLink>
      <CustomLink active={isActive('mr')} to='/blog/category/mr'><span>Mixed Reality</span></CustomLink>
      <CustomLink active={isActive('pa')} to='/blog/category/pa'><span>Platform</span></CustomLink>
    </StyledContainer>
  )
};

export default BlogHeader;