import styled, { css } from 'styled-components';

export const SubmenuContainer = styled.div`
position: absolute;
top: 54px;
left: -10px;
display: ${({ showIt }) => showIt ? 'flex' : 'none'};
height: ${({ showIt }) => showIt ? 'auto' : '0'};
overflow: ${({ showIt }) => showIt ? 'visible' : 'hidden'};

${({ theme }) => theme.media.onlyMobile`
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  margin-top: ${({ showIt }) => showIt ? '1rem' : '0'};
`}

display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0 !important;
background-color: ${({ theme }) => theme.colors.white};
box-shadow: 0px 5px 15px rgba(122, 122, 122, 0.2);
border-radius: 8px;
z-index: 9;
width: 210px;
a {
  padding: 15px;
  ${({ theme }) => theme.media.onlyMobile`
    padding: 2.25% 15px;
  `}
  ${({ dark }) => dark && css`
    color: ${({ theme }) => theme.colors.primaryBlack};
  `}
}
.submenuDivider {
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  margin: 0 10px;
  width: calc(100% - 20px);
}
`;