import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding-top: calc(60px - 0.83em);
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  ${({ theme }) => theme.media.onlyMobile`
    padding: 3rem 0 0 0;
    >*:not(:last-child) {
      padding: 0 25px;
    }
  `}

  h2 {
    color: ${({ theme }) => theme.colors.primaryBlack};
    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  
  p {
    color: ${({ theme }) => theme.colors.primaryBlack};
    text-align: justify;
    ${({ theme }) => theme.media.exceptMobile`
      max-width: 50%;
    `}
    ${({ theme }) => theme.media.onlyMobile`
      font-size: ${({ theme }) => theme.font.size20px};
      line-height: 34px;
    `}
  }

  > div:last-child {
    text-align: center;
    position: relative;
    overflow: hidden;

    img {
      width: 80%;
      position: relative;
      z-index: 1;
    }

    svg {
      width: 80%;
      position: absolute;
      z-index: 0;
      left: 10%;
      top: 8.5rem;
      ${({ theme }) => theme.media.onlyMobile`
        width: 135%;
        left: calc(-35% / 2);
        top: 5rem;
      `}
    }
  }
`;