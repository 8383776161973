import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import { NavbarStyleContext } from "../../context/navbarStyle/navbarStyle";

import { ReactComponent as Logo } from './../../assets/images/logoDark.svg';
import { ReactComponent as Hamburger } from './../../assets/images/menu-mobile-hamburger.svg';
import { StyledNavbar, ElementsContainer } from './navbar.style';
import { SubmenuItem } from "./submenuItem/submenuItem.component";
import MobileMenu from './mobileMenu/mobileMenu.component';
import { menuItems } from "./menuItems";

import useMedia from "../../hooks/useMedia";
import useLocationChange from "../../hooks/useLocationChange";
import ScheduleMeetingButton from "../scheduleMeetingButton/scheduleMeetingButton.component";


const Navbar = () => {
  const { isDark, setDark, isInitialDark, bgColor, setBgColor } = useContext(NavbarStyleContext);
  const [showMobile, setShowMobile] = useState(false);
  const [previousColor, setPreviousColor] = useState(bgColor);
  const [previousDarkness, setPreviousDarkness] = useState(isDark);
  const { isMobile } = useMedia();
  let navigate = useNavigate();
  let lastScrollTop = 0;

  useEffect(() => {
    const onScroll = (e) => {
      const extractColorsFromRGB = (rgbString) => {
        const stringReplaced = rgbString.replace(/ /g, '');
        const rgbArray = (stringReplaced.slice(stringReplaced.startsWith('rgba') ? 5 : 4, -1).split(',').map(e => parseInt(e)));
        rgbArray.length === 4 && rgbArray.splice(-1);
        return rgbArray;
      }
      const getLightnessOfRGB = (rgbArray) => {
        const highest = Math.max(...rgbArray);
        const lowest = Math.min(...rgbArray);
        return (highest + lowest) / 2 / 255;
      };

      const scrollTop = window.scrollY;
      let downscrolling = false;
      if (scrollTop > lastScrollTop) {
        downscrolling = true;
      }
      const headerHeight = isMobile ? 60 : 90;
      const firstDiv = document.querySelector('main>div:first-child');
      var nodes = document.querySelectorAll('main>div');
      var el;
      for (var i = downscrolling ? (nodes.length - 1) : 0; downscrolling ? i >= 0 : i < nodes.length; downscrolling ? i-- : i++) {
        el = nodes[i];
        if (el.offsetTop <= (scrollTop + headerHeight)) {
          const bgColor = extractColorsFromRGB(window.getComputedStyle(el).backgroundColor);
          if (el === firstDiv) {
            if (scrollTop === 0) {
              setDark(isInitialDark);
              setBgColor('transparent');
            } else {
              if (getLightnessOfRGB(bgColor) > 0.5) {
                setDark(false);
              } else {
                setDark(true);
              }
              setBgColor(`rgb(${bgColor[0]}, ${bgColor[1]}, ${bgColor[2]})`);
            }
            break;
          }
          if (getLightnessOfRGB(bgColor) > 0.5) {
            setDark(false);
          } else {
            setDark(true);
          }
          setBgColor(`rgb(${bgColor[0]}, ${bgColor[1]}, ${bgColor[2]})`);
          break;
        }
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isInitialDark]);

  useLocationChange(() => {
    setShowMobile(false);
  });

  useEffect(() => {
    if (showMobile) {
      setPreviousColor(bgColor);
      setBgColor('white');
      setPreviousDarkness(isDark);
      setDark(false);
      document.body.setAttribute("style", "overflow: hidden; height: 100%;");
    } else {
      document.body.setAttribute("style", "overflow: visible; ");
      setBgColor(previousColor);
      setDark(previousDarkness);
    }
  }, [showMobile]);

  const renderItems = () => {
    return menuItems.map((item, index) => {
      if (item.submenu) return (<SubmenuItem key={index} item={item} dark={isDark} />);
      return (<NavLink key={index} to={item.href}>{item.title}</NavLink>);
    })
  };

  return (
    <>
      <StyledNavbar open={showMobile} style={{ backgroundColor: bgColor }} dark={isDark}>
        {isMobile ?
          <>
            <Logo onClick={() => navigate('/')} />
            <Hamburger onClick={() => setShowMobile(!showMobile)} />
            <MobileMenu open={showMobile} setOpen={setShowMobile} />
          </>
          :
          <>
            <Logo onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
            <ElementsContainer dark={isDark}>
              {renderItems()}
              <ScheduleMeetingButton>Schedule a meeting</ScheduleMeetingButton>
            </ElementsContainer>
          </>
        }
      </StyledNavbar>
    </>
  );
};

export default Navbar;