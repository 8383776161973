import styled, { css } from 'styled-components';

export const StyledNavbar = styled.nav`
  position: fixed;
  top: 0;
  padding-left: 15%;
  padding-right: 5%;
  box-sizing: border-box;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 999;
  transition: background-color .2s linear;
  ${({ theme }) => theme.media.onlyMobile`
    padding: 10px;
    height: 60px;
  `}
  opacity: 0.95;
  ${({ theme }) => theme.media.onlyMobile`
    opacity: ${({ open }) => open ? '1' : '0.95'};
  `}

  > svg path:not(path:nth-last-child(2)){
    fill: ${({ dark, theme }) => dark ? theme.colors.white : 'rgb(0, 17, 69)'};
  }
`;

export const StyledMobileNavbar = styled.nav`
  position: fixed;
  top: 0;
  z-index: 999;
  transition: background-color .2s linear;
  width: 100%;
  display: flex;

  > svg path:not(path:nth-last-child(2)){
    fill: ${({ dark, theme }) => dark ? theme.colors.white : 'rgb(0, 17, 69)'};
  }
`;

export const ElementsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  a, > div {
    @media (min-width: 1400px) {
      padding: 0 15px;
    }
    @media (min-width: 1600px) {
      padding: 0 27px;
    }
    padding: 0 5px;
    text-decoration: none;
    width: max-content;
    color: ${({ dark, theme }) => dark ? theme.colors.white : theme.colors.primaryBlack};

    ${({ dark }) => dark && css`
      > svg path {
        stroke: ${({ theme }) => theme.colors.white};
      }
    `}
  }
  button {
    margin-left: 10px;
  }
`;