import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: ${({ theme }) => theme.colors.lightGray};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primaryBlack};
  ${({ theme }) => theme.media.onlyMobile`
    flex-direction: column;
    padding: 0.75rem 0 0 0;
    
    >*:not(:last-child) {
      padding: 0 25px;
    }
  `}

  > div {
    :first-child {
      padding-top: 4rem;
      box-sizing: border-box;
      ${({ theme }) => theme.media.exceptMobile`
        padding-right: 5rem;
        max-width: 60%;
      `}

      h2 {
        margin-bottom: 3rem;
        span {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
    :last-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 625px;
      overflow: scroll;
      scrollbar-width: none;
      ${({ theme }) => theme.media.exceptMobile`
        padding-right: 8rem;
      `}
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin-top: 35px;
  padding: 3rem 2rem;
  box-sizing: border-box;
  max-width: 375px;

  h3 {
    // font-family: 'Gilroy-Bold';
    font-weight: 400;
    font-size: ${({ theme }) => theme.font.size24px};
    line-height: 30px;
    margin-bottom: 1rem;
    margin-top: 3.5rem;
  }
  
  p {
    // font-family: 'Gilroy-Medium';
    font-weight: 400;
    font-size: ${({ theme }) => theme.font.size18px};
    line-height: 30px;
    color: ${({ theme }) => theme.colors.gray};
  }
`;