import { useState } from "react";
import { StyledButton } from "./button.style";
import { ReactComponent as ArrowRight } from './../../assets/images/arrow-right.svg';

const Button = ({ children, onClick, withArrow, ...otherProps }) => {
  const [isHover, setHover] = useState(false);
  return (
    <StyledButton
      {...otherProps}
      className={isHover ? "hovered" : ''}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}>
      {children}
      {withArrow && <ArrowRight />}
    </StyledButton>
  );
}

export default Button;