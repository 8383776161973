import { useState, useEffect } from 'react';

import { ReactComponent as DownArrow } from '../../assets/images/chevron-down.svg';

import { StyledContainer } from './scrollUp.style';

const ScrollUp = () => {
  const [scrollUpShow, setScrollUpShow] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = (event) => {
    if (window.scrollY > 150) {
      setScrollUpShow(true);
    } else {
      setScrollUpShow(false);
    }
  };

  const handleScrollUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const isActive = () => scrollUpShow ? 1 : 0;

  return (
    <StyledContainer active={isActive()} onClick={handleScrollUp}>
      <DownArrow />
    </StyledContainer>
  )
};

export default ScrollUp;