import vrPerformance from './../../../../assets/images/solutions/vr-performance.webp';
import vrImmersion from './../../../../assets/images/solutions/vr-immersion.webp';
import { ReactComponent as Actor } from './../../../../assets/images/solutions/actor.svg';
import { ReactComponent as Immersive } from './../../../../assets/images/solutions/immersive.svg';
import { ReactComponent as Microphone } from './../../../../assets/images/solutions/microphone.svg';

import { StyledContainer, StyledAdvantagesContainer, StyledImageContainer } from './virtualTours.style';

import useMedia from '../../../../hooks/useMedia';
import P from '../../../../utils/paragraph/paragraph.style';
import AnimatedHeading from '../../../../utils/animatedHeading/animatedHeading';


const VirtualTours = () => {
  const { isMobile } = useMedia();

  return (
    <>
      <StyledContainer>
        <AnimatedHeading><span>360&deg;</span><br />Virtual tours</AnimatedHeading>
        <h3>A virtual trip to a real place as{!isMobile && <br />} a <span>fantastic way to ....</span></h3>
        <P>
          Imagine a walk around the Sanofi drug factory.  You can see the most advanced production lines and technological processes up close. <b>All without leaving the office.</b>
        </P>
        <P>
          You can travel to places you may never be able to go, including destination that are off-limits or simply dangerous.
        </P>
        <P>
          A large portion of substantive knowledge and a great way to onboarding new employees and show the scale of the enterprise - offices around the world and the most advanced factories.
        </P>
        <StyledAdvantagesContainer>
          <h5>Find out about the product advantages</h5>
          <div>
            <div>
              <Actor />
              <span>Real actors</span>
            </div>
            <div>
              <Microphone />
              <span>Professional<br /> voice-over</span>
            </div>
            <div>
              <Immersive />
              <span>Virtual detailing<br /> boards</span>
            </div>
          </div>
        </StyledAdvantagesContainer>
        <StyledImageContainer>
          <img src={vrImmersion} alt='' />
          <img src={vrPerformance} alt='' />
        </StyledImageContainer>
      </StyledContainer>
    </>
  );
};

export default VirtualTours;