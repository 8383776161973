import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  
  >div {
    display: flex;
    flex-direction: row;
    padding: 150px 0;
    justify-content: space-between;
    align-items: center;
    ${({ theme }) => theme.media.onlyMobile`
      flex-direction: column;
      padding-bottom: 2.5rem;
    `}

    h2 {
      color: ${({ theme }) => theme.colors.primaryBlack};
    }

    p {
      text-align: justify;
      ${({ theme }) => theme.media.exceptMobile`
        max-width: 60%;
      `}
    }
  }
`;

export const SolutionsContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 10rem;
  padding-bottom: 10rem;
  ${({ analytic }) => analytic && css`
    padding: 10rem 0 10rem 15%;
    margin-top: -10rem;
    ${({ theme }) => theme.media.onlyMobile`
      padding: 3rem 0;;
      margin-top: -3rem;
    `}
  `}

  ${({ theme }) => theme.media.onlyMobile`
    flex-direction: column-reverse;
    padding-top: 3rem;
    padding-bottom: 3rem;
  `}

  .solution_image {
    position: relative;
    ${({ theme }) => theme.media.exceptMobile`
      max-width: 50%;
    `}
    ${({ analytic }) => analytic && css`
      img {
        width: 100%;
        ${({ theme }) => theme.media.onlyMobile`
          width: 150%;
          padding-bottom: 2rem;
          padding-left: 25px;
        `}
      }
    `}
  }

  .solution_content {
    overflow: hidden;
    ${({ theme }) => theme.media.exceptMobile`
      max-width: ${({ analytic }) => analytic ? '45%' : '40%'};
    `}
    >button {
      margin-top: 2rem;
      ${({ theme }) => theme.media.onlyMobile`
      margin: 1rem 0;
      `}
    }

    ${({ analytic }) => analytic && css`
      ${({ theme }) => theme.media.onlyMobile`
        padding: 0 25px;
      `}
    `}

    >svg {
      padding-bottom: 1rem;
    }

    h3 {
      // font-family: 'Gilroy-Medium';
      font-size: ${({ theme }) => theme.font.size64px};
      line-height: 70px;
      margin-top: 0;
      color: ${({ analytic, theme }) => analytic ? theme.colors.white : theme.colors.primaryBlack};
    }

    > p {
      color: ${({ analytic, theme }) => analytic ? theme.colors.white : theme.colors.primaryBlack};
      ${({ analytic }) => analytic && css`
        max-width: 80%;
      `}
    }

    .bullet_container {
      display: grid;
      grid-template-columns: auto auto;
      padding-top: 2rem;
      ${({ theme }) => theme.media.onlyMobile`
        grid-template-columns: auto;
      `}
    }
  }
`;