import styled from "styled-components";

export const BulletContainer = styled.div`
  .bullet_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    .bullet_object {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      
      > svg, > div {
        padding: 0.5rem;
        ${({ theme }) => theme.media.exceptMobile`
          max-width: 60%;
        `}
      }
      ${({ theme }) => theme.media.onlyMobile`
        > div {
          width: 100%;
        }
      `}

      
      h3 {
        margin: 0;
        line-height: 45px;
        // font-family: 'Gilroy-Medium';
        font-weight: 400;
        font-size: ${({ theme }) => theme.font.size20px};
      }
      
      p {
        // font-family: 'Gilroy-Medium';
        font-weight: 400;
        font-size: ${({ theme }) => theme.font.size16px};
        line-height: 24px;
      }
    }
  }
`;