import { useState, useEffect } from 'react';
import ClipLoader from "react-spinners/ClipLoader";

import { StyledComponent, StyledHeader, StyledItemsContainer, StyledBlogItem } from './articlesContainer.style';

import axios from '../../axios';
import NavigationalButton from '../../utils/navigationalButton/navigationalButton';

const Container = ({ title, logo, category, readMore }) => {
  const [blogItems, setBlogItems] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('articles', {
      params: {
        limit: 6,
        by_category: category
      }
    })
      .then(response => {
        setBlogItems(response.data.data);
      })
      .catch((reason) => console.error(reason));
  }, [category]);

  useEffect(() => {
    setLoading(blogItems.length === 0)
  }, [blogItems]);

  const renderItems = () => (
    blogItems.map((item, index) => (
      <StyledBlogItem key={index}>
        <img className='poster' src={item.headerImage?.thumb?.url} alt='' />
        <p className='type_and_date'>
          <span>{item.categories[0] + ' '}&#8226;</span>{' '}
          {item.createdAt}
        </p>
        <h3>{item.title}</h3>
        <p className='summary'>{item.bodyShort}</p>
        <div className='footer'>
          <div className='author'>
            <img src={item.author.avatar?.webp?.url} alt='' />
            <div>
              <span>{`${item.author.firstName} ${item.author.lastName}`}</span><br />
              <span className='position'>{item.author.occupiedPosition}</span>
            </div>
          </div>
          <NavigationalButton navigateTo={`/blog/${item.slug}`} withArrow></NavigationalButton>
        </div>
      </StyledBlogItem>
    ))
  );

  return (
    <StyledComponent>
      <StyledHeader>
        <div>
          {logo}
          <h2>{title}</h2>
        </div>
        {readMore && <NavigationalButton navigateTo={`/blog/category/${category}`} withArrow>Read more in this category</NavigationalButton>}
      </StyledHeader>
      <StyledItemsContainer>
        {renderItems()}
        <ClipLoader loading={isLoading} size={80} color='#1DB8BA' />
      </StyledItemsContainer>
    </StyledComponent>
  );
};

export default Container;