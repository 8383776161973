import 'react-alice-carousel/lib/alice-carousel.css';
import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import { useTheme } from 'styled-components';

import { ReactComponent as PrevBtn } from './../../../assets/images/industries/prev-btn.svg';
import { ReactComponent as NextBtn } from './../../../assets/images/industries/next-btn.svg';
import { StyledContainer, CarouselItem, CarouselContainer } from './industries.style';

import { items } from './sliderData';
import useMedia from './../../../hooks/useMedia';
import P from '../../../utils/paragraph/paragraph.style';
import AnimatedHeading from '../../../utils/animatedHeading/animatedHeading';
import NavigationalButton from '../../../utils/navigationalButton/navigationalButton';


const Industries = () => {
  const theme = useTheme();
  const { isMobile } = useMedia();

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  const renderPrevBtn = ({ isDisabled }) => (
    !isMobile && <PrevBtn />
  );

  const renderNextBtn = ({ isDisabled }) => (
    !isMobile && <NextBtn />
  );

  const renderItems = () => (
    items.map((item) => (
      <div>
        <CarouselItem>
          <img src={item.img} alt='' />
          <h3>{item.title}</h3>
          <p>{item.text}</p>
        </CarouselItem>
      </div>
    ))
  );

  return (
    <>
      <StyledContainer id="industries">
        <div>
          <AnimatedHeading>
            <span style={{ color: theme.colors.primary }}>For which</span><br />
            industries
            <NavigationalButton navigateTo='/#contact-us'>Contact us</NavigationalButton>
          </AnimatedHeading>
          <P>A large portion of substantive knowledge and a great way to onboarding
            new employees and show the scale of the enterprise - offices around the
            world and the most advanced factories.</P>
        </div>
      </StyledContainer>
      <CarouselContainer>
        <AliceCarousel
          mouseTracking
          items={renderItems()}
          responsive={responsive}
          controlsStrategy='responsive'
          disableDotsControls={true}
          infinite={true}
          renderPrevButton={renderPrevBtn}
          renderNextButton={renderNextBtn}
        />
      </CarouselContainer>
    </>
  )
};

export default Industries;