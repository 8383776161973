import { StyledContainer } from './keyFeatures.style';
import features from './featuresList';

import P from '../../../../utils/paragraph/paragraph.style';
import BulletList from '../../../../components/bulletList/bulletList.component';
import AnimatedHeading from '../../../../utils/animatedHeading/animatedHeading';

const KeyFeatures = () => (
  <StyledContainer>
    <AnimatedHeading><span>Key</span> Features</AnimatedHeading>
    <P>
      A large portion of substantive knowledge and a great way to onboarding new employees and show the scale of the enterprise - offices around the world and the most advanced factories.
    </P>
    <BulletList items={features} />
  </StyledContainer>
);

export default KeyFeatures;