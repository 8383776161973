import { useEffect, useState } from 'react';

import { media } from './../theme/media';

const useMedia = () => {
  const [isMobile, setIsMobile] = useState(window.matchMedia(media.onlyMobile).matches);
  const [isTablet, setIsTablet] = useState(window.matchMedia(media.onlyTablet).matches);
  const [isDesktop, setIsDesktop] = useState(window.matchMedia(media.onlyDesktop).matches);

  const handleWindowResize = () => {
    setIsMobile(window.matchMedia(media.onlyMobile).matches);
    setIsTablet(window.matchMedia(media.onlyTablet).matches);
    setIsDesktop(window.matchMedia(media.onlyDesktop).matches);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return {
    isMobile: isMobile,
    isTablet: isTablet,
    isDesktop: isDesktop,
  };
}

export default useMedia;
