import { useState } from 'react';
import { useTheme } from 'styled-components';

import { StyledContainer, StyledPrevLink } from './exploreMore.style';

import { ReactComponent as ArrowRight } from './../../assets/images/arrow-right.svg';

const ExploreMore = ({ nextItem, prevItem }) => {
  const theme = useTheme();
  const [isPrevLinkHovered, setPrevLinkHovered] = useState(false);
  const [isNextLinkHovered, setNextLinkHovered] = useState(false);

  const hoveredStyles = {
    fontSize: '64px',
    opacity: '1',
    lineHeight: '75px',
    color: theme.colors.primary
  }
  const isHovered = (prop) => prop ? 1 : 0;
  return (
    <StyledContainer>
      <h3>Explore more products</h3>
      <div>
        <StyledPrevLink prev='1' hovered={isHovered(isPrevLinkHovered)} style={isPrevLinkHovered ? hoveredStyles : {}} onMouseEnter={() => setPrevLinkHovered(true)} onMouseLeave={() => setPrevLinkHovered(false)} to={prevItem.href}><ArrowRight /> {prevItem?.title}</StyledPrevLink>
        <StyledPrevLink hovered={isHovered(isNextLinkHovered)} style={isNextLinkHovered ? hoveredStyles : {}} onMouseEnter={() => setNextLinkHovered(true)} onMouseLeave={() => setNextLinkHovered(false)} to={nextItem.href}>{nextItem?.title} <ArrowRight /></StyledPrevLink>
      </div>
    </StyledContainer>
  );
};

export default ExploreMore;