import NavigationalButton from "../../../utils/navigationalButton/navigationalButton";

export const developmentSteps = [
  {
    title: 'Creative Workshops',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eu dui cum justo.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  },
  {
    title: 'Choice of technology',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eu dui cum justo.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  },
  {
    title: 'Storyboarding and scriptwriting',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eu dui cum justo.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  },
  {
    title: 'Main Development',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eu dui cum justo.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  },
  {
    title: 'Sound and Voice-over recordings',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eu dui cum justo.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  },
];

export const implementationSteps = [
  {
    title: 'Presentation of the finished product',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eu dui cum justo.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  },
  {
    title: 'Hardware delivery',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eu dui cum justo.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  },
  {
    title: 'Operation training',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eu dui cum justo.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  },
  {
    title: 'Support for the first implementations',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eu dui cum justo.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  },
];

export const cardsItems = [
  {
    title: 'Offering',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eu dui cum justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    steps: []
  },
  {
    title: 'Development',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eu dui cum justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    steps: developmentSteps,
    button: <NavigationalButton navigateTo="/#contact-us" withArrow>Go to price</NavigationalButton>
  },
  {
    title: 'Implementation',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eu dui cum justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    steps: implementationSteps,
    button: <NavigationalButton navigateTo="/#contact-us" withArrow>Contact us </NavigationalButton>
  }
];