import styled from "styled-components";

export const H2 = styled.h2`
  // font-family: 'Gilroy-Medium';
  font-size: ${({ theme }) => theme.font.size64px};
  line-height: 70px;
  font-weight: 400;

  >span {
    color: ${({ theme }) => theme.colors.primary};
  }
`;