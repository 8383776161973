import { createContext, useState, useEffect } from "react";

export const NavbarStyleContext = createContext();

function NavbarStyleProvider({ children }) {
  const [dark, setDark] = useState(false);
  const [initialDark, setInitialDark] = useState(false);
  const [bgColor, setBgColor] = useState('');

  useEffect(() => {
    setDark(initialDark);
    setBgColor('transparent');
  }, [initialDark]);

  return (
    <NavbarStyleContext.Provider value={{
      isDark: dark,
      setDark: setDark,
      isInitialDark: initialDark,
      setInitialDark: setInitialDark,
      bgColor: bgColor,
      setBgColor: setBgColor,
    }}>
      {children}
    </NavbarStyleContext.Provider>
  );
}

export default NavbarStyleProvider;
