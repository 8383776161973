import parse from 'html-react-parser';
import { useState, useEffect } from 'react';
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';

import {
  StyledContainer,
  StyledTag,
  StyledTagsContainer,
  StyledBlogItem,
  StyledContentContainer,
  StyledShareContainer
} from './content.style';
import { ReactComponent as FBIcon } from '../../../assets/images/socials/fb.svg';
import { ReactComponent as LinkedInIcon } from '../../../assets/images/socials/linked-in.svg';
import { ReactComponent as TTIcon } from '../../../assets/images/socials/twitter.svg';

import axios from '../../../axios';
import useMedia from '../../../hooks/useMedia';
import ClipLoader from "react-spinners/ClipLoader";
import NavigationalButton from '../../../utils/navigationalButton/navigationalButton';


const Content = ({ item }) => {
  const { isMobile } = useMedia();
  const [blogItems, setBlogItems] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const renderTags = () => (
    item?.tags?.map((tag, index) => (
      <StyledTag key={index}>
        {tag}
      </StyledTag>
    ))
  );

  useEffect(() => {
    axios.get('articles', {
      params: {
        limit: 2
      }
    })
      .then(response => {
        setBlogItems(response.data.data);
      })
      .catch((reason) => console.error(reason));
  }, []);

  useEffect(() => {
    setLoading(blogItems.length === 0)
  }, [blogItems]);

  const renderBlogItems = (isMobile) => (
    blogItems.map((item, i) => (
      <StyledBlogItem key={i}>
        <img className='poster' src={item.headerImage.thumb.url} alt='' />
        <p className='type_and_date'>
          <span>{item.categories[0] + ' '}&#8226;</span>{' '}
          {item.createdAt}
        </p>
        <h1>{item.title}</h1>
        <p className='summary'>{item.bodyShort}</p>
        <div className='footer'>
          <div className='author'>
            <img src={item.author.avatar.webp.url} alt='' />
            <div>
              <span>{`${item.author.firstName} ${item.author.lastName}`}</span><br />
              <span className='position'>{item.author.occupiedPosition}</span>
            </div>
          </div>
          <NavigationalButton withArrow navigateTo={`/blog/${item.slug}`}>{isMobile ? '' : 'Read more '}</NavigationalButton>
        </div>
      </StyledBlogItem>
    ))
  );

  const shareURL = window.location.href;
  const shareText = "Some share text"

  return (
    <>
      <StyledContainer>
        {!isMobile &&
          <StyledShareContainer>
            <h4>Share this</h4>
            <div>
              <LinkedinShareButton url={shareURL}>
                <LinkedInIcon />LinkedIn
              </LinkedinShareButton>
              <FacebookShareButton
                url={shareURL}
                quote={shareText}
              >
                <FBIcon />Facebook
              </FacebookShareButton>
              <TwitterShareButton
                url={shareURL}
                title={shareText}>
                <TTIcon />Twitter
              </TwitterShareButton>
            </div>
          </StyledShareContainer>}
        <div>{item?.body && parse(item?.body)}</div>
      </StyledContainer>
      <div style={{ border: '1px solid #F5F7F8', margin: '0px 15%', padding: '0px' }}></div>
      {isMobile &&
        <>
          <StyledShareContainer>
            <h4>Share this</h4>
            <div>
              <LinkedinShareButton url={shareURL}>
                <LinkedInIcon />LinkedIn
              </LinkedinShareButton>
              <FacebookShareButton
                url={shareURL}
                quote={shareText}
              >
                <FBIcon />Facebook
              </FacebookShareButton>
              <TwitterShareButton
                url={shareURL}
                title={shareText}>
                <TTIcon />Twitter
              </TwitterShareButton>
            </div>
          </StyledShareContainer>
          <div style={{ border: '1px solid #F5F7F8', margin: '0px 15%', padding: '0px' }}></div>
        </>
      }
      {item?.tags?.length !== 0 && <>
        <StyledTagsContainer>
          <h4>Tag article</h4>
          <div>{renderTags()}</div>
        </StyledTagsContainer>
        <div style={{ border: '1px solid #F5F7F8', margin: '0px 15%', padding: '0px' }}></div>
      </>}
      <StyledContentContainer>
        <h2>you might<br /><span>also like</span></h2>
        <div>
          {renderBlogItems(isMobile)}
          <ClipLoader loading={isLoading} size={80} color='#1DB8BA' />
        </div>
      </StyledContentContainer>
    </>
  );
};

export default Content;