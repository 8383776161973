import brochureImage from './../../../../assets/images/solutions/brochure-image.webp';

import { StyledHeaderContainer, StyledContentContainer } from './interactiveBrochure.style';
import features from './featuresList';

import useMedia from '../../../../hooks/useMedia';
import Donuts from '../../../../utils/donuts/donuts';
import P from '../../../../utils/paragraph/paragraph.style';
import BulletList from '../../../../components/bulletList/bulletList.component';
import AnimatedHeading from '../../../../utils/animatedHeading/animatedHeading';

const InteractiveBrochure = () => {
  const { isMobile } = useMedia();

  return (
    <>
      <StyledHeaderContainer>
        <div>
          <AnimatedHeading><span>Interactive</span> brochure</AnimatedHeading>
          <P>
            Virtual Reality is the use of computer technology to create a simulated environment. Unlike traditional user interfaces, VR places the user inside an experience. Instead of viewing a screen in front of them, users are immersed and able to interact with 3D world.
          </P>
        </div>
      </StyledHeaderContainer>
      {!isMobile && <Donuts style={{ top: '-10rem', zIndex: '0' }} />}
      <StyledContentContainer>
        <div>
          <img src={brochureImage} alt='' />
        </div>
        <div>
          <AnimatedHeading side='right'>Functionalities</AnimatedHeading>
          <BulletList items={features} />
        </div>
      </StyledContentContainer>
    </>
  );
};

export default InteractiveBrochure;