import { useState, useRef } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import {
  StyledContainer,
  StyledInfoSection,
  StyledContactForm,
  StyledForm,
  StyledInput,
  StyledModal,
  StyledModalBackdrop
} from "./contactUs.style";

import avarat1 from './../../../assets/images/contactUs/avatar-1.webp';
import avarat2 from './../../../assets/images/contactUs/avatar-2.webp';

import useMedia from '../../../hooks/useMedia';
import Button from './../../../utils/button/button';
import P from "../../../utils/paragraph/paragraph.style";
import CheckboxInput from "../../../utils/checkboxInput/checkboxInput";
import AnimatedHeading from "../../../utils/animatedHeading/animatedHeading";
import axios from '../../../axios';

const ContactUs = () => {
  const { isMobile } = useMedia();
  const formRef = useRef(null);
  const [inputs, setInputs] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [modalText, setModalText] = useState('');

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }
    setInputs(values => ({ ...values, [name]: value }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
    if (!validateForm()) {
      setModalText('Some of the fields are empty or unchecked. Please make sure you have fulfilled all the fields correctly.')
    } else {
      setRequestLoading(true);
      axios.post('contacts/pharmatiq', inputs)
        .then((response) => {
          if (response.status === 200) {
            setModalText('Your request has been sent. Thank you.')
          }
        })
        .catch((reason) => {
          setModalText(reason.response.data?.error);
        })
        .finally(() => setRequestLoading(false));
    }
  }

  const validateForm = () => getAllInputNames().map((item) => validateField(item)).every((el) => el);

  const validateField = (field) => {
    if (inputs[field] && (inputs[field].length > 0 || (typeof inputs[field] === 'boolean' && inputs[field]))) return true;
    return false;
  }

  const getAllInputNames = () => {
    let elements = [...formRef.current.querySelectorAll("input, textarea")];
    return elements.map((item) => item.name);
  }

  const renderBackdrop = (props) => <StyledModalBackdrop {...props} />

  return (
    <>
      <div style={{ border: '1px solid #EBEBEB', margin: '0 15%', padding: '0' }}></div>
      <StyledContainer id="contact-us">
        <StyledInfoSection>
          <AnimatedHeading><span>Talk</span><br />with us</AnimatedHeading>
          <P>Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc.</P>
          <div className="info_section">
            <h5>CONTACT WITH OFFICE</h5>
            <p>office@pharmatiq.pl<br />
              +48 601 635 615</p>
          </div>
          <div className='author'>
            <img src={avarat1} alt='' />
            <div>
              <span>Jakub Burdajewicz</span><br />
              <span className='position'>jakub@pharmatiq.pl</span>
            </div>
          </div>
          <div className='author'>
            <img src={avarat2} alt='' />
            <div>
              <span>Maciej Kawiński</span><br />
              <span className='position'>maciej@pharmatiq.pl</span>
            </div>
          </div>
        </StyledInfoSection>
        <StyledContactForm>
          <AnimatedHeading type='h3' side="right">Write <span>to us</span></AnimatedHeading>
          <StyledForm ref={formRef} onSubmit={handleSubmit}>
            {isMobile ?
              <>
                <Input label="First name" name='first_name' value={inputs.first_name || ''} onChange={handleChange} />
                <Input label="Last name" name='last_name' value={inputs.last_name || ''} onChange={handleChange} />
              </>
              :
              <div>
                <Input style={{ width: 'calc(50% - 10px)', marginRight: '10px' }} label="First name" name='first_name' value={inputs.first_name || ''} onChange={handleChange} />
                <Input style={{ width: 'calc(50% - 10px)', marginLeft: '10px' }} label="Last name" name='last_name' value={inputs.last_name || ''} onChange={handleChange} />
              </div>}
            <Input label="Adres e-mail" name='email' value={inputs.email || ''} onChange={handleChange} />
            <Input label="Message" text={true} inputStyle={{ height: '245px' }} name='message' value={inputs.message || ''} onChange={handleChange} />
            <CheckboxInput
              inputName='tos_acceptance'
              inputId="tos_acceptance"
              value={inputs.tos_acceptance || false}
              onChange={handleChange}
              label="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, sed scelerisque eu consequat ut mauris viverra neque."
            />
            <div><Button type='submit' withArrow>Submit</Button></div>
          </StyledForm>
        </StyledContactForm>
      </StyledContainer>
      <StyledModal
        show={showModal}
        onHide={() => setShowModal(false)}
        renderBackdrop={renderBackdrop}>
        <div>
          {requestLoading ? null : <div>{modalText}</div>}
          <ClipLoader loading={requestLoading} size={16} color='#1DB8BA' />
        </div>
      </StyledModal>
    </>
  );
};

const Input = ({ style, label, text, inputStyle, name, value, onChange }) => (
  <StyledInput style={style}>
    <label>{label}</label>
    {text ?
      <textarea style={inputStyle} name={name} value={value} onChange={onChange} />
      :
      <input style={inputStyle} name={name} value={value} onChange={onChange} />
    }
  </StyledInput>
);

export default ContactUs;