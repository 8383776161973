import styled from 'styled-components';

export const StyledDonuts = styled.div`
  z-index: 1;
  height: 0;
  position: relative;
  left: 0;
  right: 0;
  padding: 0 !important;
  overflow: visible;
  transform: ${({ right }) => right ? 'rotate(180deg)' : 'rotate(0deg)'};
`;