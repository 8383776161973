import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBlack};
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 9rem;
  padding-bottom: 9rem;
  ${({ theme }) => theme.media.onlyMobile`
    padding-top: 2rem;
    padding-bottom: 2rem;
  `}

  h3 {
    // font-family: 'Gilroy-Medium';
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    // padding-bottom: 2.5rem;
    position: relative;
    z-index: 1;
    ${({ theme }) => theme.media.exceptMobile`
      padding-bottom: 2.5rem;
    `}
  }

  > div {
    display: flex;
    align-content: center;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    ${({ theme }) => theme.media.onlyMobile`
      flex-direction: column-reverse;
      gap: 1rem;
    `}

    a {
      text-decoration: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      z-index: 1;

      svg > path {
          stroke: ${({ theme }) => theme.colors.white};
          stroke-width: 1;
      }
    }
  }
  `;

export const StyledPrevLink = styled(Link)`
  opacity: 0.45;
  // font-family: 'Gilroy-Medium';
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  transition: all 0.3s ease-in-out;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.media.onlyMobile`
    font-size: ${({ theme }) => theme.font.size25px};
    line-height: 35px;
  `}

  > svg {
    transition: all 0.3s ease-in-out;
    width: 30px;
    height: 30px;
    ${({ prev }) => prev && css`
      transform: rotate(180deg);
    `}
    padding-left: 0.8rem;
    ${({ theme }) => theme.media.onlyMobile`
      padding-left: 0.4rem;
      width: 16px;
      height: 16px;
    `}
  }

  ${({ hovered }) => hovered && css`
    font-size: 64px;
    opacity: 1;
    line-height: 75px;
    color: ${({ theme }) => theme.colors.primary};
    >svg {
      width: 64px;
      height: 64px;
      >path {
        stroke: ${({ theme }) => theme.colors.primary} !important;
      }
    }
  `}
  `;


// export const StyledNextLink = styled(Link)`
// opacity: 0.45;
//   // font-family: 'Gilroy-Medium';
//   font-weight: 400;
//   // font-size: ${({ theme }) => theme.font.size64px};
//   // line-height: 75px;
//   font-size: 30px;
//   line-height: 35px;
//   transition: all 0.3s ease-in-out;
//   color: ${({ theme }) => theme.colors.white};
//   ${({ theme }) => theme.media.onlyMobile`
//     font-size: ${({ theme }) => theme.font.size30px};
//     line-height: 40px;
//   `}

//   > svg {
//     padding-left: 1.3rem;
//     width: 64px;
//     height: 64px;
//     ${({ theme }) => theme.media.onlyMobile`
//       padding-left: 0.65rem;
//       width: 32px;
//       height: 32px;
//     `}
//   }
// `;