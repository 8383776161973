import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import useLocationChange from '../../../hooks/useLocationChange';
import { NavbarStyleContext } from '../../../context/navbarStyle/navbarStyle';
import categoryName from '../../../utils/categoryName/categoryName';

import Header from '../../../containers/blogCategory/header/header.container';
import Content from '../../../containers/blogCategory/content/content.container';

const Index = () => {
  const { setInitialDark, setBgColor } = useContext(NavbarStyleContext);
  let { categoryId } = useParams();

  useLocationChange((location) => {
    setTimeout(() => {
      setInitialDark(true);
      setBgColor('');
    }, 100);
  });

  return (
    <>
      <Header title={categoryName(categoryId)} category={categoryId} />
      <Content title={categoryName(categoryId)} category={categoryId} />
    </>
  );
};

export default Index;