import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => theme.media.onlyMobile`
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 3rem;
  `}

  ${({ theme }) => theme.media.exceptMobile`
    > div {
      width: 50%;
    }
  `}
`;

export const StyledImageContainer = styled.div`
  position: relative;
  height: 715px;
  ${({ theme }) => theme.media.onlyMobile`
    width: 100%;
  `}

  img {
    position: absolute;
    right: 0;
  }
`;

export const StyledContentContainer = styled.div`
  > div {
    ${({ theme }) => theme.media.exceptMobile`
      padding-left: 6rem;
    `}
    overflow: hidden;

    ${({ theme }) => theme.media.onlyMobile`
      h2 {
        font-size: ${({ theme }) => theme.font.size64px};
        line-height: 75px;
      }
    `}

    > p {
      color: ${({ theme }) => theme.colors.gray};
      ${({ theme }) => theme.media.onlyMobile`
        font-size: ${({ theme }) => theme.font.size20px};
        line-height: 36px;
      `}
    }
  }
`;