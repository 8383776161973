import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  position: relative;

  >div {
    display: flex;
    flex-direction: row;
    padding: 50px 0 350px;
    justify-content: space-between;
    ${({ theme }) => theme.media.exceptMobile`
      align-items: center;
    `}
    ${({ theme }) => theme.media.onlyMobile`
      flex-direction: column;
    `}

    h2 {
      color: ${({ theme }) => theme.colors.primaryBlack};
    }

    p {
      text-align: justify;
      color: ${({ theme }) => theme.colors.primaryBlack};
      ${({ theme }) => theme.media.exceptMobile`
        max-width: 60%;
      `}
      ${({ theme }) => theme.media.onlyMobile`
        font-size: ${({ theme }) => theme.font.size20px};
      `}
    }

    button {
      margin-top: 2rem;
    }
  }
`;

export const CarouselContainer = styled.div`
  padding-bottom: 10rem;
  
  .alice-carousel {
    z-index: 1;
    margin-top: -300px;
  }

  .alice-carousel__prev-btn {
    cursor: pointer;
    width: unset;
    position: absolute;
    left: -7.5vw;
    top: 210px;
  }
  
  .alice-carousel__next-btn {
    cursor: pointer;
    width: unset;
    position: absolute;
    left: -7.5vw;
    top: 304px;
  }
`;

export const CarouselItem = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  border-radius: 12px;
  margin: 0 15px 0 0;
  padding: 30px 35px 35px 35px;

  img {
    width: 100%;
  }
`;