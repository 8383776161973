import { AnimationOnScroll } from 'react-animation-on-scroll';

import background from './../../../../assets/images/solutions/pa-background-image.webp';

import {
  StyledContainer,
  StyledContentContainer
} from './banner.style';

import useMedia from '../../../../hooks/useMedia';
import P from '../../../../utils/paragraph/paragraph.style';
import NavigationalButton from "../../../../utils/navigationalButton/navigationalButton";

const Banner = () => {
  const { isMobile } = useMedia();

  return (
    <StyledContainer>
      <AnimationOnScroll animateIn='animate__fadeInRight'>
        <StyledContentContainer>
          <div>
            <h2>Stay in control</h2>
            <P>
              Access to sales data, analyzes and reports in one place. The available application allows you to manage wherever you are
            </P>
            <NavigationalButton withArrow navigateTo='/#contact-us'>Request demo</NavigationalButton>
          </div>
          {isMobile && <img src={background} alt='' />}
        </StyledContentContainer>
      </AnimationOnScroll>
    </StyledContainer>
  )
}

export default Banner;