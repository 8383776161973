import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  margin-bottom: -17.5rem;
  margin-top: -1rem;
  padding-top: 4rem;
  ${({ theme }) => theme.media.onlyMobile`
    margin-bottom: -5rem;
  `}

  h2 {
    margin-top: 0;
    // font-family: 'Gilroy-Medium';
    font-weight: 400;
    font-size: ${({ theme }) => theme.font.size64px};
    line-height: 70px;
    ${({ theme }) => theme.media.onlyMobile`
      font-size: ${({ theme }) => theme.font.size50px};
      line-height: 70px;
    `}
    span {
      color: ${({ theme }) => theme.colors.primary};
      ${({ theme }) => theme.media.onlyMobile`
        font-size: ${({ theme }) => theme.font.size64px};
        line-height: 75px;
      `}
    }
  }
  
  p {
    color: ${({ theme }) => theme.colors.primaryBlack};
    text-align: left;
    ${({ theme }) => theme.media.exceptMobile`
      max-width: 60%;
      `}
    ${({ theme }) => theme.media.onlyMobile`
      font-size: ${({ theme }) => theme.font.size20px};
    `}
  }

  h5 {
    // font-family: 'Gilroy-Bold';
    font-weight: 400;
    font-size: ${({ theme }) => theme.font.size18px};
    line-height: 21px;
    color: ${({ theme }) => theme.colors.primary};
    margin-top: 5rem;
  }

  img {
    position: relative;
    z-index: 2;
    ${({ theme }) => theme.media.onlyMobile`
      width: 100%;
    `}
  }
`;