import VRStudent from './../../../../assets/images/solutions/virtual-reality-student.webp';
import { ReactComponent as ArrowDown } from './../../../../assets/images/down-arrow.svg';
import { ReactComponent as Circle } from './../../../../assets/images/circle.svg';

import {
  StyledContainer,
  StyledContentContainer,
  StyledDownScrollContainer
} from './header.style';

import useMedia from '../../../../hooks/useMedia';
import P from '../../../../utils/paragraph/paragraph.style';
import scrollDown from "../../../../utils/scrollDown/scrollDown";

const Header = () => {
  const { isMobile } = useMedia();

  return (
    <>
      <StyledContainer>
        <StyledContentContainer>
          <div>
            <h1>Virtual Reality</h1>
            <h2>Detaling & Educational Platform</h2>
            <P>
              The volume of communication to HCPs creates a clutter that depreciates the value of traditional detailing's. Introducing VR and AR features to our sales material will make our product promotions more memorable, which in turn will boost recommendations.
              <br /><br />
              HyperReality technology will enable us to create cutting-edge content showing how our products work from another perspective
            </P>
            {!isMobile &&
              <>
                <StyledDownScrollContainer>
                  <Circle />
                  <div onClick={scrollDown}>
                    Scroll down <ArrowDown />
                  </div>
                </StyledDownScrollContainer>
              </>
            }
          </div>
          {!isMobile && <div>
            <img src={VRStudent} alt='' />
          </div>}
        </StyledContentContainer>
      </StyledContainer>
    </>
  );
};

export default Header;