import { useRef, useEffect } from "react";
import { useTheme } from 'styled-components';

import { ReactComponent as Elipse } from './../../../assets/images/solutions/blue-elipse.svg';
import { ReactComponent as ARImage } from './../../../assets/images/solutions/deco-vector.svg';
import mobileImage from './../../../assets/images/solutions/app_pharma.webp';
import webDashboard from './../../../assets/images/solutions/web_dashboard.webp';
import questLeft from './../../../assets/images/solutions/quest_left.webp';
import questRight from './../../../assets/images/solutions/quest_right.webp';
import questHead from './../../../assets/images/solutions/quest_head.webp';

import { StyledContainer, SolutionsContainer } from './solutions.style';
import platformFeatures from "./platformFeaturesList";

import useMedia from './../../../hooks/useMedia';
import P from "../../../utils/paragraph/paragraph.style";
import useVisibility from "../../../hooks/useVisibility";
import categoryLogo from '../../../utils/categoryLogo/categoryLogo';
import BulletList from "../../../components/bulletList/bulletList.component";
import AnimatedHeading from "../../../utils/animatedHeading/animatedHeading";
import NavigationalButton from "../../../utils/navigationalButton/navigationalButton";


const Solutions = () => {
  const { isMobile } = useMedia();
  const theme = useTheme();
  const leftControllerRef = useRef();
  const headerControllerRef = useRef();
  const rightControllerRef = useRef();
  const isLeftControllerVisible = useVisibility(leftControllerRef);
  const isHeaderControllerVisible = useVisibility(leftControllerRef);
  const isRightControllerVisible = useVisibility(leftControllerRef);
  const controllerPixelRange = isMobile ? 100 : 200;
  const headerPixelRange = isMobile ? 200 : 500;

  const clamp = (num, rangeNumber) => Math.min(Math.max(num, -rangeNumber / 2), rangeNumber / 2);
  const scrollHandler = (ref, pixelRange, visibility) => {
    const scrollPos = window.scrollY + window.innerHeight;
    const topPos = ref.current.getBoundingClientRect().top + window.scrollY;
    const bottomPos = ref.current.getBoundingClientRect().top + scrollPos + ref.current.getBoundingClientRect().height;
    const percentageViewportPos = ((scrollPos - topPos) / (bottomPos - topPos) - 0.5) * pixelRange;
    if (visibility) {
      ref.current.style.transform = `translateY(${clamp(percentageViewportPos, pixelRange)}px)`;
    }
  }

  useEffect(() => {
    const onScroll = (e) => {
      scrollHandler(leftControllerRef, controllerPixelRange, isLeftControllerVisible);
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isLeftControllerVisible, controllerPixelRange]);

  useEffect(() => {
    const onScroll = (e) => {
      scrollHandler(headerControllerRef, headerPixelRange, isHeaderControllerVisible);
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isHeaderControllerVisible, headerPixelRange]);

  useEffect(() => {
    const onScroll = (e) => {
      scrollHandler(rightControllerRef, controllerPixelRange, isRightControllerVisible);
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isRightControllerVisible, controllerPixelRange]);

  return (
    <>
      <StyledContainer>
        <div>
          <AnimatedHeading><span style={{ color: theme.colors.primary }}>Check out</span><br />our solutions</AnimatedHeading>
          <P>A large portion of substantive knowledge and a great way to onboarding
            new employees and show the scale of the enterprise - offices around the
            world and the most advanced factories.</P>
        </div>
      </StyledContainer>
      <SolutionsContainer id="case-study">
        <div className='solution_content' style={{ paddingTop: isMobile ? '2rem' : '' }}>
          {categoryLogo('vr')}
          <AnimatedHeading type="h3">Virtual Reality detailings system</AnimatedHeading>
          <P>The volume of communication to HCPs creates
            a clutter that depreciates the value of traditional
            detailing's. Introducing VR and AR features to our
            sales material will make our product promotions
            more memorable, which in turn will boost recommendations
          </P>
          <NavigationalButton withArrow navigateTo='/solutions/vr'>Learn more</NavigationalButton>
        </div>
        <div className='solution_image'>
          <Elipse style={{ width: isMobile ? '86%' : '172%', position: 'relative', left: isMobile ? '15%' : '-50%' }} />
          <img ref={leftControllerRef} src={questLeft} alt='' style={
            isMobile ?
              { position: 'absolute', left: '-35px', top: '-35px', width: '26%', transform: `translateY(-${controllerPixelRange * 0.5}px)` }
              :
              { position: 'absolute', left: '-400px', top: '0', width: '43%', transform: `translateY(-${controllerPixelRange * 0.5}px)` }
          } />
          <img ref={rightControllerRef} src={questRight} alt='' style={
            isMobile ?
              { position: 'absolute', right: '-35px', top: '-35px', width: '26%', transform: `translateY(-${controllerPixelRange * 0.5}px)` }
              :
              { position: 'absolute', right: '-76px', top: '0', width: '43%', transform: `translateY(-${controllerPixelRange * 0.5}px)` }
          } />
          <img ref={headerControllerRef} src={questHead} alt='' style={
            isMobile ?
              { position: 'absolute', left: '15px', top: '20px', width: '100%', transform: `translateY(-${headerPixelRange * 0.5}px)` }
              :
              { position: 'absolute', right: '0', bottom: '0', width: '190%', transform: `translateY(-${headerPixelRange * 0.5}px)` }
          } />
        </div>
      </SolutionsContainer>
      <SolutionsContainer>
        <div className='solution_image'>
          <img src={mobileImage} alt='' style={{ verticalAlign: 'bottom', width: '100%', zIndex: '1', position: 'relative' }} />
          <ARImage style={
            isMobile ?
              { position: 'absolute', right: '-135px', top: '-80px', zIndex: '0', transform: 'scale(0.6)' }
              :
              { position: 'absolute', right: '45px', top: '30px', zIndex: '0' }
          } />
        </div>
        <div className='solution_content'>
          {categoryLogo('ar')}
          <AnimatedHeading type="h3" side='right'>Augmented Reality</AnimatedHeading>
          <P>
            HyperReality technology will enable us to create cutting-edge content showing how our products work from another perspective
          </P>
          <NavigationalButton withArrow navigateTo='/solutions/ar'>Learn more</NavigationalButton>
        </div>
      </SolutionsContainer>

      {/* <SolutionsContainer style={{ backgroundColor: 'rgba(245, 247, 248, 0.5)' }}>
        <div className='solution_content'>
          {categoryLogo('mr')}
          <AnimatedHeading type='h3'>Mixed Reality sales solution</AnimatedHeading>
          <P>
            The volume of communication to HCPs creates a
            clutter that depreciates the value of traditional
            detailing's. Introducing VR and AR features to our
            sales material will make our product promotions more
            memorable, which in turn will boost recommendations
          </P>
          <NavigationalButton withArrow navigateTo='/solutions/mr'>Learn more</NavigationalButton>
        </div>
        <div className='solution_image'>
          <img src={mobileImage} alt='' style={{ verticalAlign: 'bottom', width: '100%' }} />
          <ARImage style={{ position: 'absolute', right: '45px', top: '30px', zIndex: '-1' }} />
        </div>
      </SolutionsContainer> */}

      <div style={{ background: '#1C232F', color: 'white', padding: '0', position: 'relative' }}>
        <SolutionsContainer className="layout_container" analytic>
          <div className='solution_content'>
            {categoryLogo('pa')}
            <AnimatedHeading type='h3'>Platform Analytical</AnimatedHeading>
            <P>
              The volume of communication to HCPs creates a
              clutter that depreciates the value of traditional
              detailing's. Introducing VR and AR features to our
              sales material will make our product promotions more memorable,
              which in turn will boost recommendations
            </P>
            {!isMobile && <NavigationalButton withArrow navigateTo='/solutions/pa'>Learn more</NavigationalButton>}
            <BulletList items={platformFeatures} />
            {isMobile && <NavigationalButton withArrow navigateTo='/solutions/pa'>Learn more</NavigationalButton>}
          </div>
          <div className='solution_image'>
            <img src={webDashboard} alt='' />
          </div>
        </SolutionsContainer>
      </div>
    </>
  );
};

export default Solutions;