import styled from 'styled-components';
import Modal from 'react-overlays/Modal';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  padding-bottom: 6rem;
  padding-top: 3rem;
  ${({ theme }) => theme.media.onlyMobile`
    flex-direction: column;
    padding-bottom: 2rem;
  `}
`;

export const StyledInfoSection = styled.div`
  ${({ theme }) => theme.media.exceptMobile`
    width: 40%;
  `}

  h2 {
    text-align: left;
    color: ${({ theme }) => theme.colors.primaryBlack};
    margin: 0;
    
    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  p {
    text-align: justify;
    color: ${({ theme }) => theme.colors.primaryBlack};
    ${({ theme }) => theme.media.exceptMobile`
      padding-right: 20%;
    `}
  }

  .info_section {
    h5 {
      text-transform: uppercase;
      margin-bottom: 0;
      // font-family: 'Gilroy-Bold';
      font-size: ${({ theme }) => theme.font.size16px};
      line-height: 30px;
      color: ${({ theme }) => theme.colors.primaryBlack};
    }

    p {
      margin-top: 0.5rem;
      // font-family: 'Gilroy-Medium';
      font-size: ${({ theme }) => theme.font.size18px};
      line-height: 30px;
      color: ${({ theme }) => theme.colors.darkBlue};
    }
  }

  .author {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.5rem;

    color: ${({ theme }) => theme.colors.primaryBlack};
    // font-family: 'Gilroy-Bold';
    font-size: ${({ theme }) => theme.font.size16px};
    line-height: 30px;

    > div {
      padding: 0.5rem;
      padding-left: 0.75rem;

      .position {
        color: ${({ theme }) => theme.colors.darkBlue};
        // font-family: 'Gilroy-Medium';
        font-size: ${({ theme }) => theme.font.size18px};
        line-height: 30px;
      }
    }
  }
`;

export const StyledContactForm = styled.div`
  overflow: hidden;
  ${({ theme }) => theme.media.exceptMobile`
    width: 60%;
  `}

  h3 {
    // font-family: 'Gilroy-Bold';
    font-size: ${({ theme }) => theme.font.size24px};
    line-height: 28px;
    color: ${({ theme }) => theme.colors.primaryBlack};

    > span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  input, textarea { 
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 12px;
  }

  button {
    float: right;
    ${({ theme }) => theme.media.onlyMobile`
      margin-top: 2rem;
    `}
  }

  > div:not(:last-child) {
    margin-bottom: 30px;
  }
`;

export const StyledInput = styled.div`
  display: inline-grid;

  input, textarea {
    height: 65px;
    margin: 8px 0 10px 0;
    padding: 20px 15px;
  }

  label {
    color: ${({ theme }) => theme.colors.primaryBlack};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: ${({ theme }) => theme.font.size16px};
    line-height: 19px;
  }
`;

export const StyledModal = styled(Modal)`
  position: fixed;
  width: 400px;
  z-index: 999999;
  top: calc(50% - 30px);
  left: calc(50% - 200px);
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
`;

export const StyledModalBackdrop = styled.div`
  position: fixed;
  z-index: 999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;