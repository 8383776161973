import questLeft from './../../../../assets/images/solutions/quest_left.webp';
import questRight from './../../../../assets/images/solutions/quest_right.webp';
import questHead from './../../../../assets/images/solutions/quest_head.webp';
import { ReactComponent as Elipse } from './../../../../assets/images/solutions/blue-elipse.svg';

import { StyledContainer } from './explanation.style';

import useMedia from '../../../../hooks/useMedia';
import P from '../../../../utils/paragraph/paragraph.style';
import AnimatedHeading from '../../../../utils/animatedHeading/animatedHeading';

const Explanation = () => {
  const { isMobile } = useMedia();

  return (
    <>
      <StyledContainer>
        <AnimatedHeading><span>What</span><br /> is Virtual Reality?</AnimatedHeading>
        <P>
          Virtual Reality is the use of computer technology to create a simulated environment. {isMobile && <><br /><br /></>}Unlike traditional user interfaces, VR places the user inside an experience. Instead of viewing a screen in front of them, users are immersed and able to interact with
          3D world.
        </P>
        <div style={{ textAlign: 'center' }}>
          <img src={questLeft} alt='' style={{ width: '10%', transform: 'translate(-60%, -55%)' }} />
          <img src={questRight} alt='' style={{ width: '10%', transform: 'translate(475%, -250%)' }} />
          <img src={questHead} style={{ width: '50%', left: '-9%' }} alt='' />
          <Elipse />
        </div>
      </StyledContainer>
    </>
  );
};

export default Explanation;