import styled from 'styled-components';

export const StyledButton = styled.button`
  color: ${({ theme }) => theme.colors.white};
  width: 200px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 999px;
  border: 0;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.size16px};
  line-height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.media.onlyMobile`
    width: 100%;
    font-size: ${({ theme }) => theme.font.size14px};
  `}

  svg {
    padding-left: 1.3rem;

    path {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }

  &.hovered {
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primaryBlack};

    svg path {
      stroke: ${({ theme }) => theme.colors.primaryBlack};
    }
  }
`;