import { Link } from "react-router-dom";
import { useTheme } from 'styled-components';
import { FacebookShareButton, TwitterShareButton } from "react-share";

import VRStudent from './../../../assets/images/solutions/virtual-reality-student.webp';
import { ReactComponent as RightDonutOne } from './../../../assets/images/donut-1.svg';
import { ReactComponent as RightDonutTwo } from './../../../assets/images/donut-2.svg';
import { ReactComponent as ArrowDown } from './../../../assets/images/down-arrow.svg';
import { ReactComponent as FBIcon } from './../../../assets/images/socials/fb-borderless.svg';
import { ReactComponent as TTIcon } from './../../../assets/images/socials/twitter.svg';
import {
  StyledContainer,
  StyledContentContainer,
  WhyUsContainer,
  StyledShareContainer,
  StyledDownScrollContainer
} from './header.style';

import useMedia from "../../../hooks/useMedia";
import Donuts from "../../../utils/donuts/donuts";
import P from "../../../utils/paragraph/paragraph.style";
import scrollDown from "../../../utils/scrollDown/scrollDown";
import AnimatedHeading from "../../../utils/animatedHeading/animatedHeading";
import NavigationalButton from "../../../utils/navigationalButton/navigationalButton";


const Home = () => {
  const theme = useTheme();
  const { isMobile } = useMedia();
  const shareURL = window.location.href;
  const shareText = "Some text to share";

  return (
    <>
      <StyledContainer>
        <RightDonutOne style={{ width: '750px', position: 'absolute', right: '0', top: '250px', zIndex: '0' }} />
        <RightDonutTwo style={{ width: '910px', position: 'absolute', right: '0', top: '320px', zIndex: '0' }} />
        {!isMobile &&
          <>
            <StyledDownScrollContainer onClick={scrollDown}>
              Scroll down <ArrowDown />
            </StyledDownScrollContainer>
            <StyledShareContainer>
              <FacebookShareButton
                url={shareURL}
                quote={shareText}
              >
                <FBIcon />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareURL}
                title={shareText}>
                <TTIcon />
              </TwitterShareButton>
            </StyledShareContainer>
          </>
        }
        <StyledContentContainer>
          <div>
            <h1>Sales detailing system</h1>
            <P>Data driven VR & AR platform for increase engagement from educational content. Tailor made solution based on worldwide experience with own MSL and Doctors Team</P>
            <div>
              <NavigationalButton navigateTo='/#contact-us'>Request demo</NavigationalButton>
              <Link to='/#case-study'>View solutions</Link>
            </div>
          </div>
          <div>
            <img src={VRStudent} alt='' />
          </div>
        </StyledContentContainer>
      </StyledContainer>
      <Donuts />
      <WhyUsContainer id="about">
        <div className="content">
          <AnimatedHeading><span style={{ color: theme.colors.primary }}>Why</span> pharmatic?</AnimatedHeading>
          <div>
            We believe that <u style={{ textDecorationColor: theme.colors.primary }}>introducing</u>{' '}
            VR and AR innovative technologies to our medical detailing will enable us to
            create a meaningful impact, by making our promotional material more memorable and exciting.
          </div>
          <P>
            We believe that the pharmaceutical industry does not necessarily need more products, it needs better solutions
          </P>
        </div>
      </WhyUsContainer>
      <Donuts right />
    </>
  );
};

export default Home;
