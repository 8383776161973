import { useState } from 'react';
import { useTheme } from 'styled-components';
import { Transition } from 'react-transition-group';

import { StyledContainer, StyledCard, CardButton } from './process.style';

import Button from "../../../utils/button/button";
import Donuts from '../../../utils/donuts/donuts';
import useMedia from './../../../hooks/useMedia';
import AnimatedHeading from '../../../utils/animatedHeading/animatedHeading';
import { cardsItems } from './processSteps';


const DetailedSteps = ({ item }) => {
  const { isMobile } = useMedia();
  const [isOpen, setOpen] = useState(false);

  const renderHideButton = () => {
    if (item.steps.length !== 0 && isOpen && isMobile) {
      return (
        <Button onClick={() => setOpen(false)}>Hide details</Button>
      )
    }
  };

  const renderCardButton = () => {
    if (item.steps.length === 0) return null;
    return (
      <CardButton onClick={() => setOpen(!isOpen)}>
        {isOpen ? 'Hide details' : 'Show details'}
      </CardButton>
    )
  }

  const renderSteps = (steps) => (
    steps.map((item, i) => (
      <li key={i}>
        <h4>{item.title}</h4>
        <p>{item.body}</p>
      </li>
    ))
  );

  const transitionStyles = {
    entering: { opacity: 0.5, maxHeight: '0px' },
    entered: { opacity: 1, maxHeight: '1500px' },
    exiting: { opacity: 0.5, maxHeight: '1500px' },
    exited: { opacity: 0, maxHeight: '0px' }
  }

  return (
    <div>
      <StyledCard>
        <div>
          <h3>{item.title}</h3>
          <p>{item.body}</p>
        </div>
      </StyledCard>
      {renderCardButton()}
      <Transition in={isOpen} timeout={0}>
        {state => (
          <div className='detailed_step' style={{ ...transitionStyles[state] }}>
            <ul>
              {renderSteps(item.steps)}
            </ul>
            {item.button}
            {renderHideButton()}
          </div>
        )}
      </Transition>
    </div>
  )
}

const Process = () => {
  const theme = useTheme();
  const { isMobile } = useMedia();
  return (
    <>
      <div style={{ border: '1px solid #EBEBEB', margin: '0 15%', padding: '0' }}></div>
      {!isMobile && <>
        <Donuts style={{ top: '550px' }} scale={0.75} decorLeftPos={-85} />
      </>}
      <StyledContainer>
        <AnimatedHeading><span style={{ color: theme.colors.primary }}>Meet our</span><br /> process</AnimatedHeading>
        <div className='steps'>
          {cardsItems.map((item, i) => (<DetailedSteps key={i} item={item} />))}
        </div>
      </StyledContainer>
      {!isMobile && <>
        <Donuts right scale={0.75} decorLeftPos={-43} />
      </>}
    </>
  );
};

export default Process;