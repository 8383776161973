import ArticlesContainer from '../../../components/articlesContainer/articlesContainer.component';
import { StyledContainer } from './content.style';
import categoryLogo from '../../../utils/categoryLogo/categoryLogo';


const Content = () => {
  return (
    <StyledContainer>
      <ArticlesContainer title="Virtual Reality" logo={categoryLogo('vr')} category='vr' readMore />
      <ArticlesContainer title="Augmented Reality" logo={categoryLogo('ar')} category='ar' readMore />
      <ArticlesContainer title="Mixed Reality" logo={categoryLogo('mr')} category='mr' readMore />
    </StyledContainer>
  );
};

export default Content;