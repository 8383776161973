import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBlack};
  position: relative;
  padding-top: 10rem;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.media.onlyMobile`
    padding-top: 3rem;
    padding-bottom: 3rem;
    flex-direction: column-reverse;
  `}

  > div {
    ${({ theme }) => theme.media.exceptMobile`
      width: 60%;
    
      :last-child {
        width: 50%;
        padding-left: 3rem;
        box-sizing: border-box;
      }
    `}
    :last-child {
      overflow: hidden;
    }

    > img {
      width: 100%;
      background-color: ${({ theme }) => theme.colors.darkBlue};
      border-radius: 12px;

      :first-child {
        margin-bottom: 0.5rem;
      }

      :last-child {
        margin-top: 0.5rem;
      }
    }

     h2 {
      margin-top: 0;
      ${({ theme }) => theme.media.onlyMobile`
        font-size: ${({ theme }) => theme.font.size64px};
        line-height: 75px;
      `}
    }
  }

  span { 
    color: ${({ theme }) => theme.colors.primary};
    line-height: 112px;
  }
`;
