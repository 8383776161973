import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { useContext } from 'react';

import { NavbarStyleContext } from '../../context/navbarStyle/navbarStyle';
import useLocationChange from '../../hooks/useLocationChange';

import { main } from '../../theme/main';
import Navbar from '../../components/navbar/navbar.component';
import Footer from '../../components/footer/footer.component';
import ScrollUp from '../../utils/scrollUp/scrollUp';


const Layout = (props) => {
  const { setInitialDark, setBgColor } = useContext(NavbarStyleContext);

  useLocationChange((location) => {
    switch (location.pathname) {
      case '/':
        setInitialDark(false);
        break;
      default:
        setInitialDark(true);
        break;
    }
    setBgColor('');
  })

  return (
    <ThemeProvider theme={main}>
      <ScrollUp />
      <Navbar />
      <main style={{ paddingTop: '110px' }}>
        {props.children}
      </main>
      <Footer />
    </ThemeProvider>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout;