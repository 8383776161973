import styled from 'styled-components';

export const StyledDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBlack};
  height: 450px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 15%;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size14px};
  line-height: 24px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: ${({ theme }) => theme.colors.white};
  
  ${({ theme }) => theme.media.onlyMobile`
    height: 100%;
    flex-direction: column;
    padding: 0 25px;
  `}
  

  span a {
    color: ${({ theme }) => theme.colors.primary};
  }

  button {
    margin: 2rem 0;
    height: 47px;
    background-color: ${({ theme }) => theme.colors.white};
    // font-family: 'Gilroy-Medium';
    color: ${({ theme }) => theme.colors.black};
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.black};
      }
    }

    ${({ theme }) => theme.media.exceptMobile`
      font-size: ${({ theme }) => theme.font.size14px};
    `}
  }
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  max-width: 275px;
  text-align: left;
  ${({ theme }) => theme.media.onlyMobile`
    max-width: unset;
    padding-top: 1rem;
    padding-bottom: 1rem;
  `}

  // font-family: 'Gilroy-Regular';
  p {
    font-size: ${({ theme }) => theme.font.size16px};
    line-height: 27px;
  }

  div {
    font-size: ${({ theme }) => theme.font.size16px};
    line-height: 28px;
    font-feature-settings: 'salt' on, 'liga' off;

    h3 {
      // font-family: 'Gilroy-Bold';
      font-size: ${({ theme }) => theme.font.size16px};
      line-height: 19px;
      font-feature-settings: 'salt' on, 'liga' off;
    }
  }
`;

export const RightBlock = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  ${({ theme }) => theme.media.onlyMobile`
    flex-direction: column;
    width: 100%;
  `}

  div {
    padding: 57px 57px 10px 0;
    ${({ theme }) => theme.media.onlyMobile`
      padding-right: 0;
      padding-top: 1rem;
    `}

    h2 {
      color: ${({ theme }) => theme.colors.primary};
      // font-family: 'Gilroy-Bold';
      font-size: ${({ theme }) => theme.font.size18px};
      line-height: 21px;
      text-transform: uppercase;
    }
    p {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      line-height: 40px;
      opacity: 0.85;
    }
  }
`;

export const StyledNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  line-height: 40px;
  opacity: 0.85;
  padding-top: 0 !important;

  >a {
    font-size: ${({ theme }) => theme.font.size17px};
    color: white;
    text-decoration: none;
    width: max-content;
  }
`;