const categoryName = (category) => {
  switch (category) {
    case 'vr':
      return 'Virtual Reality';
    case 'ar':
      return 'Augmented Reality';
    case 'mr':
      return 'Mixed Reality';
    case 'pa':
      return 'Platform';
    default:
      return 'Virtual Reality';
  }
};

export default categoryName;