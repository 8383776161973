import SecondaryFooter from "./secondaryFooter/secondaryFooter.component";
import PrimaryFooter from "./primaryFooter/primaryFooter.component";

import { StyledFooter } from "./footer.style";

const Footer = () => {
  return (
    <StyledFooter>
      <PrimaryFooter />
      <SecondaryFooter />
    </StyledFooter>
  );
};

export default Footer;