import { AnimationOnScroll } from 'react-animation-on-scroll';

import { H2 } from './animatedHeading.style';

const AnimatedHeading = ({ children, type = 'h2', side = 'left' }) => {
  const getHeadingType = () => {
    switch (type) {
      case 'h2':
        return (<H2>{children}</H2>);
      case 'h3':
        return (<h3>{children}</h3>);
      case 'h4':
        return (<h4>{children}</h4>);
      case 'h5':
        return (<h5>{children}</h5>);
      default:
        return (<H2>{children}</H2>);
    }
  };

  const getAnimationClass = () => {
    switch (side) {
      case 'left':
        return 'animate__fadeInLeft';
      case 'right':
        return 'animate__fadeInRight';
      default:
        return 'animate__fadeInLeft';
    }
  }

  return (
    <AnimationOnScroll animateIn={getAnimationClass()} delay={200}>
      {getHeadingType()}
    </AnimationOnScroll>
  )
};

export default AnimatedHeading;