import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid #EDEDED;
  border-top: 0;
  
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  ${({ theme }) => theme.media.exceptMobile`
    height: 62px;
    bottom: -32px;
    border-radius: 999px;
  `}
  ${({ theme }) => theme.media.onlyMobile`
    flex-direction: column;
    padding: 2rem;
    box-sizing: border-box;
    align-items: flex-start;
  `}
`;

export const StyledLink = styled(Link)`
  position: relative;
  height: 100%;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primaryBlack};
  display: flex;
  align-items: center;

  &.hovered {
    color: ${({ theme }) => theme.colors.primary};
    box-shadow: inset 0 -4px 0 0 ${({ theme }) => theme.colors.primary}, inset 0 3.5px 0 0 ${({ theme }) => theme.colors.primary};
    background-color: #f7f7f7;
  }


  ${({ theme }) => theme.media.exceptMobile`
    ${({ active }) => active && css`
      color: ${({ theme }) => theme.colors.primary};
      box-shadow: inset 0 -3px 0 0 ${({ theme }) => theme.colors.primary};
    `}
  `}
`;