import { StyledContainer } from './header.style';

import BlogHeader from '../../../components/blogHeader/blogHeader.component';

const Home = () => {

  return (
    <>
      <StyledContainer>
        <h1>Our blog</h1>
        <BlogHeader />
      </StyledContainer>
    </>
  );
};

export default Home;