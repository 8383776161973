import ArticlesContainer from '../../../components/articlesContainer/articlesContainer.component';
import { StyledContainer } from './content.style';
import categoryLogo from '../../../utils/categoryLogo/categoryLogo';

const Content = ({ title, category }) => {
  return (
    <StyledContainer>
      <ArticlesContainer title={title} logo={categoryLogo(category)} category={category} />
    </StyledContainer>
  );
};

export default Content;