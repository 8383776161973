import styled, { css } from 'styled-components';

export const StyledHeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  z-index: 0;
  overflow: hidden;

  .header_container {
    display: flex;
    flex-direction: column;
    padding: 50px 0 100px;

    justify-content: space-between;
    align-items: center;

    h3 {
      // font-family: 'Gilroy-Bold';
      font-size: ${({ theme }) => theme.font.size15px};
      line-height: 18px;
      text-align: center;
      color: ${({ theme }) => theme.colors.primary};
      text-transform: uppercase;
    }

    h2 {
      margin-top: 10px;
      margin-bottom: 10px;
      ${({ theme }) => theme.media.onlyMobile`
        font-size: ${({ theme }) => theme.font.size38px};
      `}
    }

    p {
      text-align: center;
      ${({ theme }) => theme.media.exceptMobile`
        max-width: 40%;
      `}
    }

    button {
      margin-top: 2rem;
      color: ${({ theme }) => theme.colors.primary};
      background: transparent;
      border: 1px solid ${({ theme }) => theme.colors.primary};
      ${({ theme }) => theme.media.onlyMobile`
        max-width: 50%;
      `}
    }
  }
`;

export const StyledContentContainer = styled.div`
  margin-top: -50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${({ isLoading }) => isLoading ? 'center' : 'space-between'};
  padding-bottom: 5rem;
  ${({ theme }) => theme.media.onlyMobile`
    flex-direction: column;
    ${({ isLoading }) => isLoading && css`
      align-items: center;
    `}
  `}
`;

export const StyledBlogItem = styled.div`
  ${({ theme }) => theme.media.exceptMobile`
    max-width: 49%;
  `}
  z-index: 1;
  padding-bottom: 1rem;

  h4 {
    color: ${({ theme }) => theme.colors.secondaryBlack};
    // font-family: 'Gilroy-Medium';
    font-size: ${({ theme }) => theme.font.size34px};
    line-height: 48px;
  }

  .poster {
    width: 100%;
    border-radius: 8px;
  }

  .type_and_date {
    color: ${({ theme }) => theme.colors.blueGray};
    > span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .summary {
    color: ${({ theme }) => theme.colors.secondaryBlack};
    // font-family: 'Gilroy-Regular';
    font-size: ${({ theme }) => theme.font.size18px};
    line-height: 32px;
  }

  .footer {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;

    .author {
      display: flex;
      flex-direction: row;
      align-items: center;
      line-height: 1.5rem;

      >img {
        border-radius: 9999px;
      }

      > div {
        padding: 0.5rem;
        padding-left: 0.75rem;

        .position {
          color: #B0B0B0;
          // font-family: 'Manrope';
          font-style: normal;
          font-weight: 500;
          font-size: ${({ theme }) => theme.font.size14px};
          line-height: 19px;
        }
      }
    }

    button {
      ${({ theme }) => theme.media.exceptMobile`
        margin-right: 15px;
      `}
      ${({ theme }) => theme.media.onlyMobile`
        width: 45px;
        height: 45px;

        > svg {
          padding-left: 0;
        }
      `}
    }
  }
`;