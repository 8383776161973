import styled, { css } from "styled-components";

export const StyledContainer = styled.div`
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  transition-property: opacity, visibility;
  z-index: 999999;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  background-color: #1DB8BA;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  >svg {
    width: 42px;
    height: 42px;
    transform: rotate(180deg);

    >path {
      stroke: white;
    }
  }

  ${({ active }) => active && css`
    opacity: 1;
    visibility: visible;
  `}
`;