import styled from 'styled-components';

import backgroundImage from './../../../../assets/images/solutions/pa-header-background.svg';
import headerMask from './../../../../assets/images/solutions/mask.svg';

export const StyledContainer = styled.div`
  margin-top: -110px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  background-image: url(${backgroundImage}), url(${headerMask});
  background-size: cover;
  > svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: inherit;
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  ${({ theme }) => theme.media.onlyMobile`
    flex-direction: column;
    padding-top: 4rem;
    padding-bottom: 3rem;
  `}

  > div {
    position: relative;

    ${({ theme }) => theme.media.exceptMobile`
      :first-child {
        padding-top: 9rem;
        width: 60%;
      }
    `}

    h1 {
      // font-family: 'Gilroy-Medium';
      font-size: ${({ theme }) => theme.font.size120px};
      line-height: 124px;
      font-weight: unset;
      margin-bottom: 0;
      ${({ theme }) => theme.media.onlyMobile`
        font-size: ${({ theme }) => theme.font.size60px};
        line-height: 70px;
      `}
    }
    
    p {
      max-width: 630px;
    }
  }
`;

export const StyledDownScrollContainer = styled.div`
  position: relative;
  width: fit-content;
  padding-top: 4rem;
  margin-bottom: -11rem;
  transform: translateX(calc(8.5px - 50%));

  >div {
    position: absolute;
    bottom: 55%;
    left: calc(50% - 104px / 2);
    transform: rotate(90deg);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: ${({ theme }) => theme.font.size14px};
    line-height: 16px;
    color: #D3D3D3;

    > svg {
      transform: rotate(270deg);
    }
  }
`;