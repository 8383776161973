import styled from 'styled-components';

export const StyledCheckboxContainer = styled.label`
  display: flex;
  flex-direction: row;
  position: relative;

  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  > span.checkmark {
    margin: 5px 10px 5px 5px;
    box-sizing: border-box;
    border-radius: 5px;
    top: 0;
    left: 0;
    flex-shrink: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 1px solid #E0E0E0;
  }
  &:hover input ~ .checkmark {
    background-color: #eee;
  }
  & input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    margin: 8px;
    top: 1px;
    width: 4px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;