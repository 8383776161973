import styled from 'styled-components';

export const StyledContainer = styled.div`
  margin-top: -110px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  padding-bottom: 306px;
  ${({ theme }) => theme.media.onlyMobile`
    padding-top: 5rem;
    padding-bottom: 2rem;
  `}

  >h1 {
    padding: 8rem 0 3rem;
    margin: 0;
    // font-family: 'Gilroy-Medium';
    font-size: ${({ theme }) => theme.font.size42px};
    line-height: 52px;
    text-align: left;
    max-width: 75%;

    ${({ theme }) => theme.media.onlyMobile`
      font-size: ${({ theme }) => theme.font.size28px};
      line-height: 33px;
      padding: 2rem 0 3rem;
    `}
  }

  .type_and_date {
    color: ${({ theme }) => theme.colors.white};
    // font-family: 'Gilroy-Medium';
    > span {
      color: ${({ theme }) => theme.colors.primary};
    }

    ${({ theme }) => theme.media.onlyMobile`
      text-align: left;
      font-size: 18px;
      line-height: 21px;
    `}
  }
`;

export const StyledAuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1.5rem;

  >img {
    border-radius: 9999px;
  }

  > div {
    padding: 0.5rem;
    padding-left: 0.75rem;
    text-align: left;

    .position {
      color: ${({ theme }) => theme.colors.primary};
      // font-family: 'Gilroy-Regular';
      font-size: ${({ theme }) => theme.font.size14px};
      line-height: 16px;
    }
  }
`;

export const StyledHeaderImage = styled.div`
  ${({ theme }) => theme.media.exceptMobile`
  margin-top: -256px;
  `}
  ${({ theme }) => theme.media.onlyMobile`
    padding: 0;
  `}
  padding: 0 5%;

  >img {
    width: 100%;
    position: relative;
    border-radius: 8px;
  }
`;

export const StyledScrollProgressBar = styled.div`
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  z-index: 9999;
  background-color: ${({ theme }) => theme.colors.primary};
  height: 7px;
  width: ${({ width }) => width}%;
  transition-property: width;
  transition-duration: 1s;
  transition-timing-function: ease-out;
`;