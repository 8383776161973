import { ReactComponent as Camera } from './../../../../assets/images/solutions/camera_icon.svg';

const features = [
  {
    icon: <Camera />,
    title: 'Built with React',
    text: 'Minia has the pure React js and bootstrap native look and feels with responsive design.'
  },
  {
    icon: <Camera />,
    title: 'Built with React',
    text: 'Minia has the pure React js and bootstrap native look and feels with responsive design.'
  },
  {
    icon: <Camera />,
    title: 'Built with React',
    text: 'Minia has the pure React js and bootstrap native look and feels with responsive design.'
  },
  {
    icon: <Camera />,
    title: 'Built with React',
    text: 'Minia has the pure React js and bootstrap native look and feels with responsive design.'
  },
  {
    icon: <Camera />,
    title: 'Built with React',
    text: 'Minia has the pure React js and bootstrap native look and feels with responsive design.'
  },
  {
    icon: <Camera />,
    title: 'Built with React',
    text: 'Minia has the pure React js and bootstrap native look and feels with responsive design.'
  }
];

export default features;