import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useState } from 'react';

import './App.css';
import "animate.css/animate.min.css";
import Layout from './hoc/Layout/Layout';
import useLocationChange from './hooks/useLocationChange';
import NavbarStyleProvider from './context/navbarStyle/navbarStyle';

import Index from './pages/index/index';
import VirtualReality from './pages/solutions/virtualReality';
import AugmentedReality from './pages/solutions/augmentedReality';
import PlatformAnalytical from './pages/solutions/platformAnalytical';
import BlogIndex from './pages/blog/index';
import BlogShow from './pages/blog/show';
import BlogCategoryShow from './pages/blog/category/show';

function App() {
  const location = useLocation();
  const [previousPathname, setPreviousPathname] = useState('');

  useLocationChange((location) => {
    const scrollSmoothly = () => {
      if (location.hash) {
        let elem = document.getElementById(location.hash.slice(1));
        if (elem) {
          elem.scrollIntoView({ behavior: "smooth", block: 'center' });
        }
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    };

    if (location.pathname === previousPathname) {
      scrollSmoothly();
    } else {
      setTimeout(scrollSmoothly, 300);
      setPreviousPathname(location.pathname);
    }
  });

  return (
    <div className='App'>
      <NavbarStyleProvider>
        <Layout>
          <TransitionGroup component={null}>
            <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
              <Routes location={location}>
                <Route path="/">
                  <Route index element={<Index />} />
                  <Route path="blog">
                    <Route index element={<BlogIndex />} />
                    <Route path="category">
                      <Route path=':categoryId' element={<BlogCategoryShow />} />
                    </Route>
                    <Route path=":articleId" element={<BlogShow />} />
                  </Route>
                  <Route path="/solutions">
                    <Route path="vr" element={<VirtualReality />} />
                    <Route path="ar" element={<AugmentedReality />} />
                    <Route path="mr" element={<VirtualReality />} />
                    <Route path="pa" element={<PlatformAnalytical />} />
                  </Route>
                  <Route path="*" element={<Index />} />
                </Route>
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        </Layout>
      </NavbarStyleProvider>
    </div>
  );
}

export default App;
