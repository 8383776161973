import { NavLink } from "react-router-dom";

import { SubmenuItem } from '../submenuItem/submenuItem.component';
import { StyledMobileMenu } from './mobileMenu.style';
import ScheduleMeetingButton from "../../scheduleMeetingButton/scheduleMeetingButton.component";

import { menuItems } from '../menuItems';

const MobileMenu = ({ isDark, style, open, setOpen }) => {
  const renderItems = () => {
    return menuItems.map((item, index) => {
      if (item.submenu) return (<SubmenuItem key={index} item={item} dark={isDark} isMobile={true} />);
      return (<NavLink key={index} to={item.href}>{item.title}</NavLink>);
    })
  };
  return (
    <StyledMobileMenu style={style} open={open}>
      {renderItems()}
      <ScheduleMeetingButton externalCallback={() => setOpen(false)}>Schedule a meeting</ScheduleMeetingButton>
    </StyledMobileMenu>
  )
};

export default MobileMenu;