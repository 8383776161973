import hospitalImg from './../../../assets/images/industries/hospitals.webp';
import pharmacyImg from './../../../assets/images/industries/pharmacy.webp';
import facilitiesImg from './../../../assets/images/industries/medical-facilities.webp';

export const items = [
  {
    img: pharmacyImg,
    title: 'Firmy farmaceutyczne',
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc. Vitae, a dictum nisi turpis sagittis. Ut egestas non fringilla senectus massa sollicitudin risus egestas"
  },
  {
    img: hospitalImg,
    title: 'Szpitale',
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc. Vitae, a dictum nisi turpis sagittis. Ut egestas non fringilla senectus massa sollicitudin risus egestas"
  },
  {
    img: facilitiesImg,
    title: 'Placówki medyczne i specjalistyczne',
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc. Vitae, a dictum nisi turpis sagittis. Ut egestas non fringilla senectus massa sollicitudin risus egestas"
  },
  {
    img: facilitiesImg,
    title: 'Uniwersytety/Placówki edukacyjne',
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At turpis penatibus etiam amet tempus arcu nunc. Vitae, a dictum nisi turpis sagittis. Ut egestas non fringilla senectus massa sollicitudin risus egestas"
  },
];