import unityLogo from './../../../../assets/images/solutions/unity-logo.webp';
import { ReactComponent as Camera } from './../../../../assets/images/solutions/camera_icon.svg';

import { StyledContainer, StyledCard } from './productionProcess.style';

import P from '../../../../utils/paragraph/paragraph.style';
import AnimatedHeading from '../../../../utils/animatedHeading/animatedHeading';


const ProductionProcess = () => {

  return (
    <>
      <StyledContainer>
        <div>
          <AnimatedHeading><span>VR</span><br /> Production process</AnimatedHeading>
          <P>Creating environments in virtual reality looks completely different than creating classic 2D content (movies, graphics and photos).</P>
          <P>We must ensure that the experience is <b>maximally immersive</b>, so in our work we use <b>advanced graphic engines</b> (software) and <b>specialized equipment</b> (hardware).</P>
        </div>
        <div>
          <StyledCard>
            <Camera style={{ width: '64px', height: '64px' }} />
            <h3>Production</h3>
            <p>180o and 360&deg; degree video made with 4K cameras with real actors and recorded professional voice-over</p>
          </StyledCard>
          <StyledCard style={{ marginBottom: '35px' }}>
            <img src={unityLogo} alt='' />
            <h3>Development</h3>
            <p>The video is placed in a VR application created in the Unity environment (cross-platform game engine)</p>
          </StyledCard>
        </div>
      </StyledContainer>
    </>
  );
};

export default ProductionProcess;