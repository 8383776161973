import { ReactComponent as ARHeader } from './../../assets/images/solutions/pharmatiq-ar.svg';
import { ReactComponent as VRHeader } from './../../assets/images/solutions/pharmatiq-vr.svg';
import { ReactComponent as MRHeader } from './../../assets/images/solutions/pharmatiq-mr.svg';
import { ReactComponent as PAHeader } from './../../assets/images/solutions/pharmatiq-pa.svg';

const categoryLogo = (category) => {
  switch (category) {
    case 'vr':
      return <VRHeader />;
    case 'ar':
      return <ARHeader />;
    case 'mr':
      return <MRHeader />;
    case 'pa':
      return <PAHeader />;
    default:
      return <VRHeader />;
  }
};

export default categoryLogo;